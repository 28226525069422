// @fileoverview : 기준정보 > 사용자

import { mutateUsers } from "@/api/users/useUsersQuery";
import { customFunctions } from "@/config/customFunction";
import { EquipmentDetailForm } from "@/features/equipment/components/form/EquipmentDetailForm";
import { LocationDetailForm } from "@/features/location/detail/LocationDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { useStandardLayout } from "@/features/standardLayout/Context";
import { Filter } from "@/features/standardLayout/Filter";
import { LinkAnchor } from "@/features/standardLayout/LinkAnchor";
import { ListTable } from "@/features/standardLayout/ListTable";
import { PaginationBox } from "@/features/standardLayout/PaginationBox";
import { SearchBox } from "@/features/standardLayout/SearchBox";
import { Sorter } from "@/features/standardLayout/Sorter";
import { ActionHeader } from "@/features/standardLayout/StandardInfoActionHeader";
import {
  flexGrowable,
  flexStable,
  StandardLayoutTableWrapper,
} from "@/features/standardLayout/Styles";
import customAlert from "@/features/ui/alert/alert";
import { ConfirmForm } from "@/features/ui/confirm";
import { UsersCreateForm } from "@/features/users/components/form/UsersCreateForm";
import { UsersDetailForm } from "@/features/users/components/form/UsersDetailForm";
import { UsersUpdateForm } from "@/features/users/components/form/UsersUpdateForm";
import { handleErrorResponse } from "@/utils/errorMessage";
import { ActionIcon, Avatar, Flex, Text, Tooltip } from "@mantine/core";
import {
  AuthSignupPost201Response,
  MasterApiUsersUserIdDeleteRequest,
  UsersGet200Response,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconEdit, IconFilterPlus, IconTrash } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { Column, FormatterProps, SelectColumn } from "react-data-grid";
import { useTranslation } from "react-i18next";

const isDepartmentInformIncluded = customFunctions.ADD_USER_DEPARTMENT_INFORM;

export const Users = () => {
  const { openModal, closeModal } = useModal();
  const { selectedRows, refetch } = useStandardLayout();
  const { t } = useTranslation();

  const { mutate: deleteMutate } = useMutation(
    (params: MasterApiUsersUserIdDeleteRequest) =>
      mutateUsers.delete(params).mutationFn(params as MasterApiUsersUserIdDeleteRequest | any),
    {
      onSuccess: () => {
        refetch();
        customAlert(
          `${t("message.deleteSuccess", { item: `${t("user.user")}` })}`,
          t("common.deleteSuccess"),
          "green",
        );
      },
      onError: (error) => {
        customAlert(
          handleErrorResponse({ error, errorContext: t("user.code") }),
          `${t("common.deleteFail", { item: `${t("user.user")}` })}`,
          "red",
        );
      },
    },
  );

  const columns: readonly Column<AuthSignupPost201Response>[] = [
    {
      ...SelectColumn,
      resizable: true,
    },
    {
      key: "code",
      name: t("user.code"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <LinkAnchor
            onClick={() =>
              openModal(<UsersDetailForm UserCode={formatterProps.row.code} />, null, "")
            }
          >
            {formatterProps.row.code}
          </LinkAnchor>
        );
      },
    },
    {
      key: "name",
      name: t("user.name"),
      sortable: true,
      resizable: true,
    },
    {
      key: "username",
      name: t("user.username"),
      sortable: true,
      resizable: true,
    },
    {
      key: "equipmentCode",
      name: t("equipment.code"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <LinkAnchor
            onClick={() =>
              openModal(
                <EquipmentDetailForm equipmentCode={formatterProps.row.equipmentCode} />,
                null,
                "",
              )
            }
          >
            {formatterProps.row.equipmentCode}
          </LinkAnchor>
        );
      },
    },
    {
      key: "locationCode",
      name: t("location.code"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <LinkAnchor
            onClick={() =>
              openModal(
                <LocationDetailForm locationCode={formatterProps.row.locationCode} />,
                null,
                "",
              )
            }
          >
            {formatterProps.row.locationCode}
          </LinkAnchor>
        );
      },
    },
    ...(isDepartmentInformIncluded
      ? [
          {
            key: "departmentCode",
            name: t("department.code"),
            sortable: true,
            resizable: true,
            formatter: (formatterProps: FormatterProps<AuthSignupPost201Response, unknown>) => {
              return <Flex>{formatterProps.row.departmentCode}</Flex>;
            },
          },
        ]
      : []),
    {
      key: "printerCode",
      name: t("user.printer"),
      sortable: true,
      resizable: true,
    },
    {
      key: "options",
      name: t("common.action"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        const updateUserActionView = () => {
          openModal(
            <UsersUpdateForm formatterProps={formatterProps?.row} />,
            null,
            `${t("common.edit", { item: `${t("user.user")}` })}`,
            true,
          );
        };

        const deleteUserAction = () => {
          openModal(
            <ConfirmForm
              message={t("message.rowDelete")}
              yesCallback={() => {
                deleteMutate({ userId: formatterProps.row?.id as number });
              }}
              noCallback={() => {}}
              commonCallback={() => closeModal(null)}
            />,
            null,
            `${t("common.delete", { item: `${t("user.user")}` })}`,
          );
        };

        return (
          <Flex w="100%" h="100%" justify="center" align="center" gap="md">
            <ActionIcon variant="subtle" onClick={updateUserActionView} color="yellow">
              <IconEdit />
            </ActionIcon>
            <ActionIcon variant="subtle" onClick={() => deleteUserAction()} color="red">
              <IconTrash />
            </ActionIcon>
          </Flex>
        );
      },
    },
  ];

  const handleDelete = () => {
    if ([...selectedRows].length === 0) {
      customAlert(
        `${t("message.deleteSelect", { item: `${t("user.user")}` })}`,
        `${t("common.deleteFail", { item: `${t("user.user")}` })}`,
        "red",
      );
      return;
    }

    openModal(
      <ConfirmForm
        message={t("message.rowDelete")}
        yesCallback={() => {
          [...selectedRows].forEach((row) => {
            deleteMutate({ userId: row });
          });

          selectedRows.clear();
        }}
        noCallback={() => {}}
        commonCallback={() => closeModal(null)}
      />,
      null,
      `${t("common.delete", { item: `${t("user.user")}` })}`,
    );
  };

  return (
    <Flex w="100%" h="100%" gap="xs" direction="column">
      <Flex p="sm" justify="space-between" rowGap="md">
        <ActionHeader
          createFn={() =>
            openModal(
              <UsersCreateForm />,
              null,
              `${t("common.additional", { item: `${t("user.user")}` })}`,
              true,
            )
          }
          createDataTitle={t("user.user")}
          deleteFn={() => handleDelete()}
        />
      </Flex>
      <Flex bg="white" px="sm" direction="column" gap="xs">
        <Text fz="xl" fw={600}>
          {t("common.list", { item: `${t("user.user")}` })}
        </Text>
        <Text fz="xs">{t("common.subTitle", { item: `${t("user.user")}` })}</Text>
      </Flex>
      <Flex justify="space-between" align="flex-start" px="sm">
        <Flex wrap="wrap" styles={flexGrowable}>
          <Flex gap="xs" align="center" h="100%" wrap="wrap" direction="row">
            <Tooltip label={t("common.filterDescription")}>
              <Avatar color="blue" variant="outline">
                <IconFilterPlus />
              </Avatar>
            </Tooltip>
            <Flex direction="column" gap="xs" wrap="wrap">
              <Filter />
              <Sorter />
            </Flex>
          </Flex>
        </Flex>
        <Flex styles={flexStable}>
          <SearchBox />
        </Flex>
      </Flex>
      <Flex w="100%" h="100%" p="sm" justify="space-between" gap="md" direction="column">
        <StandardLayoutTableWrapper>
          <ListTable<UsersGet200Response, AuthSignupPost201Response> columns={columns} />
        </StandardLayoutTableWrapper>
        <PaginationBox<UsersGet200Response> />
      </Flex>
    </Flex>
  );
};

import { useQuery } from "@tanstack/react-query";

import { MasterInstance } from "@/instance/axios";
import {
  ItemsGet200Response,
  ItemsGet200ResponseRowsInner,
} from "@sizlcorp/sizl-api-document/dist/models";

export type ItemOption = {
  value: string;
  label: string;
};

export const useItemsMultiAutoComplete = (
  focus = false,
  keyword = "",
  options?: {
    onSuccess: (data: ItemOption[]) => void;
  },
) => {
    return useQuery(
        ["items-get-multi-autocomplete-keyword", keyword],
        () =>
            MasterInstance.itemsFindPost({
                search: keyword,
                searchFields: ["name", "code"],
            }).then((res) => res.data),
        {
            enabled: focus,
            select: (
                data: ItemsGet200ResponseRowsInner[]
            ): ItemOption[] =>
                data.map((Item) => {
                    return {
                        value: String(Item.code),
                        label: String(Item.name),
                    };
                }) ?? [],
            onSuccess: options?.onSuccess,
        }
    );
};

const getItemByCode = (code: string | null) =>
    MasterInstance.itemsGet({
        ...(code && { search: code }),
        searchFields: ["name", "code"],
    }).then((res) => res.data);

export const useItemsMultiCode = (
  enabled: boolean = false,
  code: string | null,
  options?: {
    onSuccess: (data?: ItemOption[]) => void;
  },
) => {
  return useQuery(["items-get-multi-code", code], () => getItemByCode(code), {
    enabled,
    select: (data: ItemsGet200Response) =>
      data?.rows?.map((Item) => {
        return {
          value: Item.code as string,
          label: Item.name as string,
        };
      }),
    onSuccess: options?.onSuccess,
  });
};

import useLedgersAllQuery from "@/api/ledgers/useGetLedgersAllQuery";
import { workLogs } from "@/api/workLogs/useWorksLogsQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { UsersDetailForm } from "@/features/users/components/form/UsersDetailForm";
import timeUtil from "@/utils/timeUtil";
import { setToLocaleString } from "@/utils/unitMark";
import { Badge, Text } from "@mantine/core";
import {
  ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner,
  WorkLogsGet200ResponseRowsInner,
  WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import {
  MRT_ColumnDef,
  MRT_PaginationState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

interface DetailLedgerProps {
  data: ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner | undefined;
}

export interface Row {
  itemCode: string;
  id: number;
  locationCode: string;
  locationData: {
    name: string;
    site_code: string;
  };
  quantity: string;
  userCode: string;
  lotData: {
    id: number;
    name: string;
    expiration: string;
  };
  itemData: {
    item_type: string;
    name: string;
    unit_text: string;
  };
  createdAt: string;
  additional: {
    workId: number;
    productionPlanId: number;
    workLogId: number;
  };
  workLogData: {
    workLogType: string;
    defectCode: string;
    defectName: string;
  };
}

interface Additional {
  workId: number;
  productionPlanId: number;
  workLogId: number;
}

export const InputTabs = (params: DetailLedgerProps) => {
  const { data } = params;

  const { data: inputData } = useLedgersAllQuery({
    query: data
      ? {
          $and: [{ locationCode: { $eq: data.locationCode } }, { lotId: { $eq: data.lotId } }],
        }
      : "",
  });

  const rows: Row[] = inputData?.data.rows ?? [];

  // rows 배열에서 row 객체 중 additional 속성이 있고, additional 객체 내에 workId가 존재하는 경우만 additional 배열에 추가
  const additionalWorkIds = rows
    .filter((row) => row.additional && row.additional.workId)
    .map((row) => row.additional.workId)
    .filter(Boolean);

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data: workLogData, isLoading } = useQuery(
    workLogs.get({
      query: {
        $and: [{ workId: additionalWorkIds[0] }],
        $or: [
          { workLogType: { $eq: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.INPUT } },
          { workLogType: { $eq: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.CANCEL_INPUT } },
        ],
      },
      page: pagination.pageIndex + 1,
      pageSize: pagination.pageSize,
      sort: "-createdAt",
      populate: [
        "itemUnit",
        "defectName",
        "alreadyCanceled",
        "creatorUser",
        "downtimeReasonName",
        "getEquipment",
      ],
    }),
  );
  const { t } = useTranslation();
  const { openModal } = useModal();

  // workLogId, 수량(logdata. quantity), 로트정보(lotname, lotid, expiration), item명, itemType, 작업자, 생성일 -> 테이블로 보여주기.
  const columns = useMemo<MRT_ColumnDef<WorkLogsGet200ResponseRowsInner>[]>(
    () => [
      {
        accessorKey: "id",
        header: "재고번호",
        size: 80,
        Cell: (rows) => {
          return <Text ta="end">{rows.row._valuesCache.id}</Text>;
        },
      },
      {
        accessorKey: "workLogType",
        header: "작업타입",
        size: 80,
        Cell: (rows) => {
          return rows.row._valuesCache.workLogType ===
            WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.INPUT ? (
            <Badge size="sm" w={40}>
              투입
            </Badge>
          ) : (
            <Badge size="sm" w={60} color="red">
              투입취소
            </Badge>
          );
        },
      },
      {
        accessorFn: (row) => [
          row.logData?.incoming?.itemData?.item_type === "PRODUCT" ? (
            <Badge size="sm" w={40}>
              제품
            </Badge>
          ) : row.logData?.incoming?.itemData?.item_type === "SEMI_PRODUCT" ? (
            <Badge size="sm" w={60}>
              반제품
            </Badge>
          ) : (
            <Badge size="sm" w={60}>
              원부자재
            </Badge>
          ),
        ],
        accessorKey: "itemType",
        header: "품목유형",
        size: 140,
        mantineEditTextInputProps: {
          type: "string",
          required: true,
        },
      },
      {
        accessorFn: (row) => [<Text>{row.logData?.incoming?.itemData?.name}</Text>],
        accessorKey: "item.name",
        header: "품목명",
        size: 100,
        mantineEditTextInputProps: {
          type: "string",
          required: true,
        },
      },
      {
        accessorFn: (row) => [
          <div>id : {row.logData?.incoming?.lotData?.id}</div>,
          <div>이름 : {row.logData?.incoming?.lotData?.name}</div>,
          <div>
            유효기한 :{" "}
            {timeUtil(
              row.logData?.incoming?.lotData?.expiration === null ||
                row.logData?.incoming?.lotData?.expiration === undefined
                ? ""
                : row.logData?.incoming?.lotData?.expiration,
            ) === "NaN-NaN-NaN"
              ? ""
              : timeUtil(
                  row.logData?.incoming?.lotData?.expiration === null ||
                    row.logData?.incoming?.lotData?.expiration === undefined
                    ? ""
                    : row.logData?.incoming?.lotData?.expiration,
                )}
          </div>,
        ],
        accessorKey: "lotData",
        header: "로트정보",
        size: 180,
        mantineEditTextInputProps: {
          type: "string",
          required: true,
        },
      },
      {
        accessorKey: "quantity",
        header: "수량",
        size: 100,
        mantineEditTextInputProps: {
          type: "number",
          required: true,
        },
        Cell: (rows) => {
          const unitText = rows.row._valuesCache.logData?.incoming?.itemData.unit_text;
          return (
            <Text ta="end">
              {setToLocaleString(
                rows.row._valuesCache.workLogType ===
                  WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.INPUT
                  ? rows.row._valuesCache.quantity
                  : `-${rows.row._valuesCache.quantity}`,
              )}{" "}
              {unitText}
            </Text>
          );
        },
      },
      {
        accessorKey: "creatorUserCode",
        header: "작업자",
        size: 120,
        mantineEditTextInputProps: {
          type: "string",
          required: true,
        },
        Cell: (rows) => {
          return (
            <DetailLink
              onClick={() =>
                openModal(
                  <UsersDetailForm UserCode={rows.row._valuesCache.creatorUserCode} />,
                  null,
                  "",
                )
              }
            >
              {rows.row._valuesCache.creatorUserCode}
            </DetailLink>
          );
        },
      },
      {
        accessorFn: (row) => dayjs(row.createdAt).format("YYYY-MM-DD HH:mm:ss"),
        accessorKey: "createdAt",
        header: "생성일",
        size: 200,
      },
    ],
    [],
  );

  const table = useMantineReactTable({
    columns,
    data: additionalWorkIds[0]
      ? ((workLogData?.data?.rows as WorkLogsGet200ResponseRowsInner[]) ?? [])
      : [],
    state: {
      isLoading,
      pagination,
    },
    editDisplayMode: "modal",
    enableEditing: false,
    enableToolbarInternalActions: false,
    enablePagination: true,
    manualPagination: true,
    paginationDisplayMode: "pages",
    mantinePaginationProps: {
      showRowsPerPage: false,
    },
    onPaginationChange: setPagination,
    rowCount: additionalWorkIds[0] ? (workLogData?.data?.total ?? 0) : 0,
    pageCount: additionalWorkIds[0] ? (workLogData?.data?.totalPages ?? 0) : 0,
  });

  return <MantineReactTable table={table} />;
};

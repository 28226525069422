import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { Button, Flex, Pagination, useMantineColorScheme } from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import type { Column, SortColumn } from "react-data-grid";
import DataGrid, { SelectColumn } from "react-data-grid";

import { wmsLog } from "@/api/delivery/useDeliveryQuery";
import { purchaseOrderItemsHeader } from "@/constants/columnHeader";
import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { LotDetailForm } from "@/features/lot/form/lotDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { PartnerDetailForm } from "@/features/partner/form/detailForm";
import { CustomFilter } from "@/features/ui/Base/List/CustomFilter/CustomFilter";
import { CustomSorter } from "@/features/ui/Base/List/CustomSorter/CustomSorter";
import { SearchBox, SearchProps } from "@/features/ui/Base/List/SearchBox/SearchBox";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { theme } from "@/styles/theme";
import DatetimeUtil from "@/utils/dateTimeUtil";
import {
  WmsLogsGet200ResponseRowsInner,
  WmsLogsGet200ResponseRowsInnerActionTypeEnum,
} from "@sizlcorp/sizl-api-document/dist/models/src/model";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

interface ColorThemeProps {
  isDarkMode: boolean;
}

export const DeliveryStatusTable = () => {
  // const [rows, setRows] = useState<any>(createRows);
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const isDarkMode: boolean = colorScheme === "light";
  const { t } = useTranslation();

  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);
  const [selectedRows, setSelectedRows] = useState((): ReadonlySet<any> => new Set());
  const [activePage, setPage] = useState(1);
  const [query, setQuery] = useState({ $and: [] });
  const [sort, setSort] = useState([]);
  const [search, setSearch] = useState<SearchProps>({
    search: "",
    searchFields: [],
    pageSize: "10",
  });
  const { openModal } = useModal();

  const isSort = sort.length;

  const searchFieldsHeader = purchaseOrderItemsHeader.filter(
    (purchaseOrderItems) => purchaseOrderItems.category === "text",
  );

  const { data: purchaseData, refetch } = useQuery(
    wmsLog.get({
      query: [
        { purchaseOrderItemId: { $not: null } },
        {
          actionType: {
            $or: [
              { $eq: WmsLogsGet200ResponseRowsInnerActionTypeEnum.OUTGOING },
              { $eq: WmsLogsGet200ResponseRowsInnerActionTypeEnum.INCOMING },
            ],
          },
        },
      ],
      search: search.search,
      searchFields: search.searchFields.length
        ? search.searchFields
        : searchFieldsHeader.map((purchaseOrderItems) => purchaseOrderItems.value),
      page: activePage,
      pageSize: Number(search.pageSize),
      sort: sort.length ? sort.join(",") : "-id",
      populate: ["locationName", "purchaseOrderItem", "lot"],
    }),
  );

  useEffect(() => {
    setPage(1);
    refetch();
  }, [
    query.$and.length,
    search.search,
    search.searchFields.length,
    search.pageSize,
    isSort,
    refetch,
  ]);

  const rows: readonly WmsLogsGet200ResponseRowsInner[] = purchaseData?.data.rows ?? [];
  const selectedRowsRef = useRef<ReadonlySet<any>>(new Set());

  const columns: readonly Column<WmsLogsGet200ResponseRowsInner>[] = [
    {
      ...SelectColumn,
      width: 70,
      maxWidth: 500,
      resizable: true,
      headerCellClass: css`
        & > * {
          justify-content: flex-start;
          padding-left: 24px;
        }
      `,
      cellClass: css`
        .rdg-checkbox-label {
          padding-left: 24px;
        }
      `,
    },
    {
      key: "purchaseOrderItemId",
      name: t("purchaseOrder.id"),
      sortable: true,
      resizable: true,
    },
    {
      key: "partnerCode",
      name: t("partner.code"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <DetailLink
            onClick={() => {
              openModal(
                <PartnerDetailForm
                  partnerCode={formatterProps?.row?.purchaseOrderItem?.partnerCode}
                />,
                null,
                "",
              );
            }}
          >
            {formatterProps?.row?.purchaseOrderItem?.partnerCode}
          </DetailLink>
        );
      },
    },
    {
      key: "itemCode",
      name: t("item.code"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps: any) => {
        return (
          <DetailLink
            onClick={() => {
              openModal(
                <ItemsDetailForm itemCode={formatterProps?.row?.actionParam?.params?.itemCode} />,
                null,
                "",
                false,
              );
            }}
          >
            {formatterProps?.row?.actionParam?.params?.itemCode}
          </DetailLink>
        );
      },
    },
    {
      key: "lotInfo.name",
      name: t("lot.name"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <DetailLink
            onClick={() => {
              openModal(
                <LotDetailForm
                  lotName={formatterProps?.row?.actionParam?.params?.lotInfo?.name}
                  lotId={formatterProps?.row?.actionParam?.params?.lotId}
                />,
                null,
                "",
              );
            }}
          >
            {formatterProps?.row?.actionParam?.params?.lotInfo?.name}
          </DetailLink>
        );
      },
    },
    {
      key: "lotInfo.expiration",
      name: t("lot.expiration"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return <>{formatterProps?.row?.lotInfo?.expiration}</>;
      },
    },
    {
      key: "quantity",
      name: t("inventory.quantity"),
      sortable: true,
      resizable: true,
      cellClass: css`
        justify-content: flex-end;
      `,
      formatter: (formatterProps) => {
        return <>{formatterProps?.row?.actionParam?.params?.quantity}</>;
      },
    },
    {
      key: "deliveryDeadline",
      name: t("delivery.deadline"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <>
            {formatterProps?.row?.purchaseOrderItem?.deliveryDeadline
              ? DatetimeUtil(formatterProps?.row?.purchaseOrderItem?.deliveryDeadline as string)
              : ""}
          </>
        );
      },
    },
  ];

  return (
    <MoldStockTableWrapper>
      <GridWrapper isDarkMode={isDarkMode}>
        <TableWrapper>
          <RetrieveWrapper>
            <Flex direction="row" gap="xs" justify="flex-start" align="flex-center">
              <CustomFilter filterType={purchaseOrderItemsHeader} setQuery={setQuery} />
              <CustomSorter sorterType={purchaseOrderItemsHeader} setSort={setSort} />
            </Flex>
            <SearchBox searchType={searchFieldsHeader} setSearch={setSearch} />
          </RetrieveWrapper>
          <DataGrid
            columns={columns ?? []}
            rows={rows as any}
            rowHeight={40}
            rowKeyGetter={(row) => row.id}
            sortColumns={sortColumns}
            selectedRows={selectedRows}
            onSelectedRowsChange={(e) => {
              setSelectedRows(e);
              const newSelectedRows = new Set(selectedRowsRef.current);
              newSelectedRows.add(e);
              selectedRowsRef.current = newSelectedRows;
            }}
          />
        </TableWrapper>
        <PaginationWrapper>
          <Pagination
            onChange={setPage}
            value={activePage}
            total={purchaseData?.data?.totalPages ?? 0}
            size="lg"
            radius="sm"
          />
        </PaginationWrapper>
      </GridWrapper>
    </MoldStockTableWrapper>
  );
};

const MoldStockTableWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const GridWrapper = styled.div<ColorThemeProps>`
  & *[role="grid"] {
    height: 440px;
    --rdg-background-color: ${(props) => (props.isDarkMode ? "white" : "none")};
    --rdg-header-background-color: ${(props) => (props.isDarkMode ? "white" : "none")};
    --rdg-color: ${(props) => (props.isDarkMode ? "black" : "white")};
    --rdg-row-hover-background-color: ${(props) =>
      props.isDarkMode ? "#f5f5f5" : theme?.colors?.gray?.[7]};
  }
  & *[role="columnheader"] {
    // color: #7d8fa9;
    font-size: 12px;
    font-family: Roboto;
    font-weight: 500;
    word-wrap: break-word;
    // border: none;
    box-shadow: none;
    display: flex;
    align-items: center;
  }
  & *[aria-colindex="1"] {
  }

  & *[role="row"] {
    height: 100px;
  }

  & *[role="gridcell"] {
    display: flex;
    align-items: center;
    // border-left: none;
    // border-right: none;
    box-shadow: none;

    & > * {
      justify-content: flex-start;
    }
  }
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const RetrieveWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  justify-content: space-between;
`;
const OptionBox = styled.fieldset`
  display: flex;
  gap: 10px;
  border: none;
`;
const OptionBtn = styled<any>(Button)`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

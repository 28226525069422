// @fileoverview : 재고관리 > WMS로그

import { LocationDetailForm } from "@/features/location/detail/LocationDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { useStandardLayout } from "@/features/standardLayout/Context";
import { Filter } from "@/features/standardLayout/Filter";
import { LinkAnchor } from "@/features/standardLayout/LinkAnchor";
import { ListTable } from "@/features/standardLayout/ListTable";
import { PaginationBox } from "@/features/standardLayout/PaginationBox";
import { SearchBox } from "@/features/standardLayout/SearchBox";
import { Sorter } from "@/features/standardLayout/Sorter";
import {
  flexGrowable,
  flexStable,
  StandardLayoutTableWrapper,
} from "@/features/standardLayout/Styles";
import { UsersDetailForm } from "@/features/users/components/form/UsersDetailForm";
import timeUtil from "@/utils/timeUtil";
import { setToLocaleString } from "@/utils/unitMark";
import { wmsStatus } from "@/utils/wmsStatus";

import { Avatar, Button, Chip, Flex, Group, Text, Tooltip } from "@mantine/core";
import {
  WmsLogsGet200Response,
  WmsLogsGet200ResponseRowsInner,
  WmsLogsGet200ResponseRowsInnerActionTypeEnum,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconFilterPlus, IconRecycle } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { Column } from "react-data-grid";
import { useTranslation } from "react-i18next";

export const WmsLogsPage = () => {
  const { openModal } = useModal();
  const { t } = useTranslation();
  const { query, setQuery, resetQueryStrings } = useStandardLayout();
  const [chipState, setChipState] = useState(() => {
    // query 배열에서 $or 키를 가진 객체를 찾고, 그 값을 반환하거나 wmsStatus의 키 목록으로 초기화
    const orQuery = query.find((q) => q && "$or" in q);
    const orQueryData = orQuery?.$or;

    // response 초기화
    const response: string[] = [];

    if (orQueryData) {
      // 1. orQueryData의 0번째 인덱스에서 string 요소들을 response에 추가
      if (Array.isArray(orQueryData[0]?.actionType)) {
        response.push(...orQueryData[0].actionType);
      }

      // 2. orQueryData의 1번째 인덱스 확인 후 조건에 따라 response에 추가
      if (orQueryData[1]?.$and) {
        const condition = orQueryData[1].$and;
        if (condition.actionFrom === "WEB" && condition.actionType === "TRANSFER") {
          response.push("TRANSFER");
        }
        if (condition.actionFrom === "ACTION" && condition.actionType === "TRANSFER") {
          response.push("TRANSFER_INPUT");
        }
      }

      // 3. orQueryData의 2번째 인덱스 확인 후 조건에 따라 response에 추가
      if (orQueryData[2]?.$and) {
        const condition = orQueryData[2].$and;
        if (condition.actionFrom === "WEB" && condition.actionType === "TRANSFER") {
          response.push("TRANSFER");
        }
        if (condition.actionFrom === "ACTION" && condition.actionType === "TRANSFER") {
          response.push("TRANSFER_INPUT");
        }
      }
    }

    // response가 비어있다면 wmsStatus의 키 목록을 기본값으로 사용
    return response.length > 0 ? response : Object.keys(wmsStatus);
  });

  // 새로고침 시 query에 따라 chipState 업데이트
  useEffect(() => {
    const orQuery = query.find((q) => q && "$or" in q);
    if (orQuery?.$or?.actionType) {
      setChipState(orQuery.$or.actionType);
    }
    if (query.length === 0) {
      setChipState(Object.keys(wmsStatus));
    }
  }, [query]);

  // chipState가 변경될 때 query 업데이트
  useEffect(() => {
    setQuery((prev) => {
      const newQuery: { $or: any[] } = { $or: [] };

      // chipState가 빈 배열일 때도 $or 조건 유지
      const safeChipState = chipState ?? [];
      const actionTypes = safeChipState.filter(
        (key: string) => key !== "TRANSFER" && key !== "TRANSFER_INPUT",
      );

      if (actionTypes.length > 0) {
        newQuery.$or.push({ actionType: actionTypes });
      } else {
        // chipState가 빈 배열일 경우에도 빈 actionType 조건 유지
        newQuery.$or.push({ actionType: [] });
      }

      if (safeChipState.includes("TRANSFER")) {
        newQuery.$or.push({ $and: { actionFrom: "WEB", actionType: "TRANSFER" } });
      }

      if (safeChipState.includes("TRANSFER_INPUT")) {
        newQuery.$or.push({ $and: { actionFrom: "ACTION", actionType: "TRANSFER" } });
      }

      // 기존 쿼리에서 $or 제거 후 병합
      const filteredPrev = prev.filter((q) => !("$or" in q));
      return [...filteredPrev, newQuery];
    });
  }, [chipState]);

  // api 쪽 수정 (query 간소화)
  // Chip 쪽 쿼리 확인해서 기본적으로 넣어주는 로직 추가 필요
  const columns: readonly Column<WmsLogsGet200ResponseRowsInner>[] = [
    {
      key: "id",
      name: t("wms.logId"),
      sortable: true,
      resizable: true,
      width: 80,
    },
    {
      key: "purchaseOrderItemId",
      name: t("wms.purchaseOrderId"),
      sortable: true,
      resizable: true,
      width: 80,
    },
    {
      key: "actionType",
      name: t("wms.actionType"),
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        return (
          <Flex>
            {row.actionFrom === "ACTION" && row.actionType === "TRANSFER"
              ? "자재투입"
              : t(row.actionType as WmsLogsGet200ResponseRowsInnerActionTypeEnum)}
          </Flex>
        );
      },
    },
    {
      key: "actionParam",
      name: t("wms.detailInfo"),
      sortable: true,
      resizable: true,
      width: 500,
      formatter: ({ row }) => {
        const actionType = row.actionType;

        if (actionType === "TRANSFER") {
          return (
            <Flex justify="flex-end">
              {`로트명 : ${row.lotInfo?.name ?? ""} 
                            품목코드 : ${row.lotInfo?.itemCode ?? ""} 
                            수량 : ${setToLocaleString(row.actionParam?.params?.quantity ?? "0")}`}
            </Flex>
          );
        } else if (actionType === "BATCH") {
          return (
            <Flex justify="flex-end">
              {`로트명 : ${row.batchLotInfo?.map((lotInfo) => lotInfo.lotInfo?.name).join(", ") ?? ""} 
                            품목코드 : ${row.batchLotInfo?.map((lotInfo) => lotInfo.lotInfo?.itemCode).join(", ") ?? ""}
                            수량 : ${row.batchLotInfo?.map((lotInfo) => setToLocaleString(lotInfo.quantity)).join(", ") ?? ""}`}
            </Flex>
          );
        } else if (actionType === "TRANSFORM") {
          return <Flex justify="flex-end">{/* Add your code here */}</Flex>;
        }

        return (
          <Flex justify="flex-end">
            {`로트명 : ${row.actionParam?.params?.lotInfo?.name ?? ""}
                            품목코드 : ${row.actionParam?.params?.itemCode ?? ""}
                            수량 : ${setToLocaleString(row.actionParam?.params?.quantity ?? "0")}`}
          </Flex>
        );
      },
    },
    {
      key: "locationCode",
      name: t("wms.fromLocation"),
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        return (
          <LinkAnchor
            onClick={() => {
              openModal(
                <LocationDetailForm
                  locationCode={
                    row.actionParam?.params?.locationCode ||
                    row.actionParam?.params?.sourceLocationCode ||
                    row.actionParam?.params?.fromLocationCode
                  }
                />,
                null,
                "",
                false,
              );
            }}
          >
            {row.actionParam?.params?.sourceLocationName}
          </LinkAnchor>
        );
      },
    },
    {
      key: "actionFrom",
      name: t("wms.toLocation"),
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        return (
          <LinkAnchor
            onClick={() => {
              openModal(
                <LocationDetailForm
                  locationCode={
                    row.actionParam?.params?.locationCode ||
                    row.actionParam?.params?.targetLocationCode ||
                    row.actionParam?.params?.toLocationCode
                  }
                />,
                null,
                "",
                false,
              );
            }}
          >
            {row.actionParam?.params?.targetLocationName}
          </LinkAnchor>
        );
      },
    },
    {
      key: "creatorUserCode",
      name: t("wms.worker"),
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        return (
          <LinkAnchor
            onClick={() => {
              openModal(<UsersDetailForm UserCode={row.creatorUserCode} />, null, "", false);
            }}
          >
            {row.creatorUserCode}
          </LinkAnchor>
        );
      },
    },
    {
      key: "createdAt",
      name: t("wms.createdAt"),
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        return <span>{timeUtil(row.createdAt ?? "")}</span>;
      },
    },
  ];

  return (
    <Flex w="100%" h="100%" gap="xs" direction="column">
      <Flex p="sm" justify="flex-end" rowGap="md">
        <Button rightIcon={<IconRecycle />} color="teal" onClick={() => resetQueryStrings()}>
          {t("common.reset")}
        </Button>
      </Flex>
      <Flex bg="white" px="sm" direction="column" gap="xs">
        <Text fz="xl" fw={600}>
          {t("wms.wms")}
        </Text>
        <Text fz="xs">{t("common.subTitle", { item: `${t("wms.wms")}` })}</Text>
      </Flex>
      <Flex justify="space-between" align="flex-start" px="sm">
        <Flex wrap="wrap" styles={flexGrowable}>
          <Flex gap="xs" align="center" h="100%" wrap="wrap" direction="row">
            <Tooltip label={t("common.filterDescription")}>
              <Avatar color="blue" variant="outline">
                <IconFilterPlus />
              </Avatar>
            </Tooltip>
            <Flex direction="column" gap="xs" wrap="wrap">
              <Filter />
              <Sorter />
            </Flex>
          </Flex>
        </Flex>
        <Flex styles={flexStable}>
          <SearchBox />
        </Flex>
      </Flex>
      <Chip.Group multiple value={chipState}>
        <Group px="md">
          {Object.keys(wmsStatus).map((key: string) => {
            return (
              <Chip
                variant="filled"
                size="sm"
                radius="xs"
                key={key}
                value={key}
                checked={chipState && chipState.includes(key)}
                onClick={(e) => {
                  const targetValue = e.currentTarget.value;
                  if (chipState.includes(targetValue)) {
                    setChipState(chipState.filter((chip: string) => chip !== targetValue));
                  } else {
                    setChipState([...chipState, targetValue]);
                  }
                }}
              >
                {t(wmsStatus[key])}
              </Chip>
            );
          })}
        </Group>
      </Chip.Group>
      <Flex w="100%" h="100%" p="sm" justify="space-between" gap="md" direction="column">
        <StandardLayoutTableWrapper>
          <ListTable<WmsLogsGet200Response, WmsLogsGet200ResponseRowsInner> columns={columns} />
        </StandardLayoutTableWrapper>
        <PaginationBox<WmsLogsGet200Response> />
      </Flex>
      {/* <WmsLogsTable /> */}
    </Flex>
  );
};

import { ledgers } from "@/api/ledgers/useLedgersQuery";
import { workLogs } from "@/api/workLogs/useWorksLogsQuery";
import { LocationDetailForm } from "@/features/location/detail/LocationDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { ProductionPlanViewForm } from "@/features/productionPlan/view";
import { SiteDetailForm } from "@/features/site/components/form/SiteDetailForm";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { MantineReactCommonTable } from "@/features/ui/mantineTable";
import { UsersDetailForm } from "@/features/users/components/form/UsersDetailForm";
import timeUtil from "@/utils/timeUtil";
import { setToLocaleString } from "@/utils/unitMark";
import { Anchor, Badge } from "@mantine/core";
import {
  ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner,
  LedgersGet200ResponseRowsInner,
  WorkLogsGet200ResponseRowsInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import {
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
} from "mantine-react-table";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

interface DetailLedgerProps {
  data: ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner | undefined;
}

export interface Row {
  itemCode: string;
  id: number;
  locationCode: string;
  locationData: {
    name: string;
    site_code: string;
  };
  quantity: string;
  userCode: string;
  name: string; // useName
  lotData: {
    name: string;
    expiration: string;
  };
  itemData: {
    item_type: string;
    name: string;
    unit_text: string;
  };
  createdAt: string;
  additional: {
    workId: number;
    productionPlanId: number;
    workLogId: number;
  };
  workLogData: {
    workLogType: string;
    defectCode: string;
    defectName: string;
  };
}

interface Additional {
  workId: number;
  productionPlanId: number;
  workLogId: number;
}

export const LedgerTabs = (params: DetailLedgerProps) => {
  const { data } = params;

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 1,
    pageSize: 10,
  });
  // 각 컬럼 sort
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  // 각 컬럼 filter
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);

  // sort를 id 별 string으로 만들어줌
  const sortString = sorting.map(({ id, desc }) => (desc ? `-${id}` : id)).join(",");

  // filter를 id 별 like를 사용하여 단어가 포함되어있는지 확인
  const filterQuery: { [key: string]: { $iLike: string } } = columnFilters.reduce<{
    [key: string]: { $iLike: string };
  }>((acc, filter) => {
    acc[filter.id] = { $iLike: `%${filter.value}%` as string };
    return acc;
  }, {});

  const commonQueryOptions = {
    pageSize: pagination.pageSize,
    page: pagination.pageIndex,
    sort: sortString || undefined,
  };

  // 추후에 search 다시하기
  const { data: ledgerData } = useQuery(
    ledgers.get({
      query: data
        ? {
            $and: [
              { locationCode: { $eq: data.locationCode } },
              { lotId: { $eq: data.lotId } },
              ...Object.keys(filterQuery).map((key) => ({ [key]: filterQuery[key] })),
            ],
          }
        : "",
      ...commonQueryOptions,
      searchFields: ["itemCode", "locationCode", "quantity", "createdAt", "userCode"],
      populate: ["item", "location", "name"],
    }),
  );

  const rows: LedgersGet200ResponseRowsInner[] = ledgerData?.data.rows ?? [];

  const additional: Additional[] = rows
    .map((row) => row.additional)
    .filter((item): item is Additional => item !== undefined);

  const { data: workLogData } = useQuery(
    workLogs.get({
      query: {
        $and: [
          { id: additional.map((row) => row?.workLogId).filter(Boolean) },
          ...Object.keys(filterQuery).map((key) => ({ [key]: filterQuery[key] })),
        ],
      },
      ...commonQueryOptions,
      populate: [
        "itemUnit",
        "defectName",
        "alreadyCanceled",
        "creatorUser",
        "downtimeReasonName",
        "getEquipment",
      ],
    }),
  );

  const rowsWithWorkLogData = rows.map((row) => {
    const matchingWorkLogData = workLogData?.data.rows?.find(
      (workLog: WorkLogsGet200ResponseRowsInner) =>
        workLog.id === (row.additional as Additional)?.workLogId,
    );
    return {
      ...row,
      workLogData: matchingWorkLogData,
    };
  });

  const { t } = useTranslation();
  const { openModal } = useModal();

  const columns = useMemo<MRT_ColumnDef<Row>[]>(
    () => [
      {
        accessorKey: "id",
        header: "재고번호",
        size: 80,
        enableColumnFilter: false,
        Cell: (rows) => {
          return <div style={{ textAlign: "right" }}>{rows.row._valuesCache.id}</div>;
        },
      },
      {
        accessorKey: "itemData.item_type",
        header: "품목유형",
        size: 140,
        enableColumnFilter: false,
        mantineEditTextInputProps: {
          type: "string",
          required: true,
        },
        Cell: (rows) => {
          return (
            <>
              {rows.row.original?.itemData.item_type === "PRODUCT" ? (
                <Badge size="sm" w={40}>
                  제품
                </Badge>
              ) : rows.row.original?.itemData.item_type === "SEMI_PRODUCT" ? (
                <Badge size="sm" w={60}>
                  반제품
                </Badge>
              ) : (
                <Badge size="sm" w={60}>
                  원부자재
                </Badge>
              )}
            </>
          );
        },
      },
      {
        accessorFn: (row) => [
          <div>이름 : {row.lotData.name}</div>,
          <div>
            유효기한 :{" "}
            {timeUtil(row.lotData.expiration === null ? "" : row.lotData.expiration) ===
            "NaN-NaN-NaN"
              ? ""
              : timeUtil(row.lotData.expiration === null ? "" : row.lotData.expiration)}
          </div>,
        ],
        accessorKey: "lotData",
        header: "로트정보",
        size: 180,
        mantineEditTextInputProps: {
          type: "string",
          required: true,
        },
      },
      {
        accessorKey: "locationData.name",
        header: "창고 이름",
        mantineEditTextInputProps: {
          type: "string",
          required: true,
        },
        Cell: (rows) => {
          return (
            <DetailLink
              onClick={() =>
                openModal(
                  <LocationDetailForm locationCode={rows.row._valuesCache.locationCode} />,
                  null,
                  "",
                )
              }
              justify="flex-start"
            >
              {rows.row._valuesCache["locationData.name"]}
            </DetailLink>
          );
        },
      },
      {
        accessorKey: "locationData.site_code",
        header: "사업장코드",
        mantineEditTextInputProps: {
          type: "string",
          required: true,
        },
        Cell: (rows) => {
          return (
            <DetailLink
              onClick={() =>
                openModal(
                  <SiteDetailForm siteCode={rows.row._valuesCache["locationData.site_code"]} />,
                  null,
                  "",
                )
              }
              justify="flex-start"
            >
              {rows.row._valuesCache["locationData.site_code"]}
            </DetailLink>
          );
        },
      },
      {
        accessorKey: "additional.productionPlanId",
        header: "생산계획번호",
        size: 60,
        enableColumnFilter: false,
        Cell: (rows) => {
          return (
            <DetailLink
              onClick={() =>
                openModal(
                  <ProductionPlanViewForm
                    ProductionPlanId={rows.row._valuesCache["additional.productionPlanId"]}
                  />,
                  null,
                  "작업 지시",
                )
              }
              justify="flex-end"
            >
              {rows.row._valuesCache["additional.productionPlanId"]}
            </DetailLink>
          );
        },
      },
      {
        accessorKey: "quantity",
        header: "수량",
        size: 100,
        mantineEditTextInputProps: {
          type: "number",
          required: true,
        },
        Cell: (rows) => {
          const unitText = rows.row.original.itemData.unit_text;
          return (
            <div style={{ textAlign: "right" }}>
              {setToLocaleString(rows.row._valuesCache.quantity)} {unitText}
            </div>
          );
        },
      },
      {
        accessorFn: (row) => row.workLogData?.workLogType,
        accessorKey: "workLogData.workLogType",
        header: "상세",
        size: 60,
        Cell: (rows) => {
          const defectName =
            rows.row.original.workLogData?.defectName === null
              ? ""
              : ` (${rows.row.original.workLogData?.defectName})`;
          return (
            <div>
              {rows.row._valuesCache["workLogData.workLogType"]
                ? t(rows.row._valuesCache["workLogData.workLogType"]) + defectName
                : ""}
            </div>
          );
        },
      },
      {
        accessorKey: "name",
        header: "작업자",
        size: 120,
        mantineEditTextInputProps: {
          type: "string",
          required: true,
        },
        Cell: (rows) => {
          return (
            <Anchor
              onClick={() =>
                openModal(<UsersDetailForm UserCode={rows.row._valuesCache.userCode} />, null, "")
              }
            >
              {rows.row._valuesCache.name}
            </Anchor>
          );
        },
      },
      {
        accessorFn: (row) => dayjs(row.createdAt).format("YYYY-MM-DD HH:mm:ss"),
        accessorKey: "createdAt",
        header: "생성일",
        size: 200,
      },
    ],
    [],
  );

  return (
    <MantineReactCommonTable
      data={rowsWithWorkLogData as Row[]}
      columns={columns}
      totalCount={rowsWithWorkLogData?.length}
      totalPages={workLogData?.data.totalPages ?? ledgerData?.data?.totalPages}
      pagination={pagination} // pagination
      setPagination={setPagination}
      sorting={sorting} // 정렬
      setSorting={setSorting}
      columnFilters={columnFilters} // 컬럼별 검색
      setColumnFilters={setColumnFilters}
    />
  );
};

import { purchaseOrders } from "@/api/purchaseOrder/usePurchaseOrder";
import { mutatePurchaseOrderItems } from "@/api/purchaseOrderItems/usePurchaseOrderItems";
import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormSelect } from "@/features/standard/Form/FormSelect";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import customAlert from "@/features/ui/alert/alert";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { PartnerAutoComplete } from "@/features/ui/autoComplete/partner/partner-autoComplete";
import { PurchaseOrderAutoComplete } from "@/features/ui/autoComplete/purchaseOrder/purchaseOrders-autoComplete";
import { UserAutoComplete } from "@/features/ui/autoComplete/user/user-autoComplete";
import { getUserCodeByUser } from "@/utils/checkData";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { Button, Flex, Text, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import {
    PurchaseOrdersGet200ResponseRowsInner,
    ScmApiPurchaseOrderItemsPostRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconCalendar } from "@tabler/icons-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";

const Create = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  Select: FormSelect,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
  Text: Text,
  Flex: Flex,
  DateInput: DateInput,
});

interface OrderItemCreateFormProps {
  orderId?: number;
  order?: PurchaseOrdersGet200ResponseRowsInner;
}

export const OrderItemsCreateForm = (props: OrderItemCreateFormProps) => {
  const { orderId, order } = props;
  const { closeModal } = useModal();
  const userCode = getUserCodeByUser();
  const [id, setId] = useState<string | null>(null);
  const queryClient = useQueryClient();
  const { mutate: createMutate } = useMutation(
    (params: ScmApiPurchaseOrderItemsPostRequest) =>
      mutatePurchaseOrderItems
        .create(params)
        .mutationFn(params as ScmApiPurchaseOrderItemsPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["purchaseOrderItems"]);
        closeModal(form.values);
        customAlert(
          `${userCode === "FLOUR" ? "수주 항목 등록에 성공하였습니다." : "발주 항목 등록에 성공하였습니다."}`,
          "등록 성공",
          "green",
        );
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({
            error,
            errorContext: `${userCode === "FLOUR" ? "수주 항목" : "발주 항목"}`,
          }),
          `${userCode === "FLOUR" ? "수주 항목 등록 실패" : "발주 항목 등록 실패"}`,
          "red",
        );
      },
    },
  );

  const { data: orderItems } = useQuery({
    ...purchaseOrders.detail({
      purchaseOrderId: orderId ?? Number(id) ?? 0,
      populate: ["location", "user", "partner", "purchaseOrderItems"],
    }),
    enabled: !!orderId || !!id,
  });

  const getInitialValues = (
    order: PurchaseOrdersGet200ResponseRowsInner | undefined,
    orderItems: AxiosResponse<PurchaseOrdersGet200ResponseRowsInner, any> | undefined,
  ) => {
    return {
      purchaseOrderId: orderId
        ? orderId
        : order
          ? order?.id
          : orderItems
            ? orderItems?.data?.id
            : undefined,
      partnerCode: orderItems?.data?.partnerCode ?? order?.partnerCode ?? undefined,
      itemCode: undefined,
      quantity: undefined,
      orderDate: order?.orderDate
        ? new Date(order?.orderDate)
        : orderItems
          ? new Date(orderItems.data?.orderDate ?? "")
          : undefined,
      deliveryDeadline: order?.deliveryDeadline
        ? new Date(order?.deliveryDeadline)
        : orderItems
          ? new Date(orderItems?.data?.deliveryDeadline ?? "")
          : undefined,
      userCode: orderItems?.data?.userCode ?? order?.userCode ?? undefined,
      targetLocationCode:
        orderItems?.data?.targetLocationCode ?? order?.targetLocationCode ?? undefined,
    };
  };

  const form = useForm({
    initialValues: getInitialValues(order, orderItems),
  });

  useEffect(() => {
    if (orderItems) {
      form.setValues(getInitialValues(order, orderItems));
    }
  }, [orderItems]);

  const onSubmit = async () => {
    try {
      createMutate({
        purchaseOrderItemsGetRequest: {
          purchaseOrderId: orderId
            ? orderId
            : order
              ? order?.id
              : orderItems
                ? orderItems?.data?.id
                : form.values.purchaseOrderId!,
          partnerCode:
            order?.partnerCode ?? orderItems?.data?.partnerCode ?? form.values.partnerCode!,
          direction: "OUTBOUND",
          itemCode: form.values.itemCode!,
          quantity: form.values.quantity!,
          orderDate:
            order?.orderDate?.toString()! ??
            orderItems?.data?.orderDate?.toString()! ??
            form.values.orderDate!,
          deliveryDeadline:
            order?.deliveryDeadline?.toString()! ??
            orderItems?.data?.deliveryDeadline?.toString()! ??
            form.values.deliveryDeadline!,
          userCode: order?.userCode ?? orderItems?.data?.userCode ?? form.values.userCode!,
          targetLocationCode:
            order?.targetLocationCode ??
            orderItems?.data?.targetLocationCode ??
            form.values.targetLocationCode!,
        },
      });
    } catch (e) {
      customAlert(
        `${userCode === "FLOUR" ? "수주 항목 등록에 실패하였습니다." : "발주 항목 등록에 실패하였습니다."}`,
        "등록 실패",
        "red",
      );
    }
  };

  return (
    <>
      <Create.Wrapper>
        {orderId || order ? null : (
          <PurchaseOrderAutoComplete
            direction="OUTBOUND"
            label={`${userCode === "FLOUR" ? "수주 코드" : "발주 코드"}`}
            placeholder={`${userCode === "FLOUR" ? "수주 코드 입력" : "발주 코드 입력"}`}
            {...form.getInputProps("purchaseOrderId")}
            clearable
            onChange={(e) => setId(e)}
          />
        )}
        <PartnerAutoComplete
          label="협력사"
          placeholder="협력사"
          {...form.getInputProps("partnerCode")}
          withAsterisk
        />
        <ItemAutoComplete
          label="품목 코드"
          placeholder="품목 코드 입력"
          {...form.getInputProps("itemCode")}
        />
        <Create.Input label="수량" placeholder="수량" {...form.getInputProps("quantity")} />
        <UserAutoComplete
          label="담당자"
          placeholder="담당자 입력"
          {...form.getInputProps("userCode")}
        />
        <LocationAutoComplete
          label="출고 창고"
          placeholder="출고 창고"
          {...form.getInputProps("targetLocationCode")}
        />
        <Create.DateInput
          popoverProps={{ withinPortal: true }}
          icon={<IconCalendar size="1.2rem" />}
          label={`${userCode === "FLOUR" ? "수주일" : "발주일"}`}
          valueFormat="YYYY-MM-DD"
          {...form.getInputProps("orderDate")}
        />
        <Create.DateInput
          popoverProps={{ withinPortal: true }}
          icon={<IconCalendar size="1.2rem" />}
          label="납기일"
          valueFormat="YYYY-MM-DD"
          {...form.getInputProps("deliveryDeadline")}
        />
        <Create.ButtonBox>
          <Create.Button color="gray" onClick={closeModal}>
            취소
          </Create.Button>
          <Create.Button
            disabled={validateFormValues({
              pageType: "purchaseOrderItems",
              values: form.values,
            })}
            onClick={onSubmit}
          >
            {userCode === "FLOUR" ? "수주 항목 등록" : "발주 항목 등록"}
          </Create.Button>
        </Create.ButtonBox>
      </Create.Wrapper>
    </>
  );
};

import { AuthSignupPost201ResponseEquipmentWorksInner, ProductionPlansGet200ResponseRowsInnerWorksInner, WorksItemGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useEffect, useState } from "react";
import { DefectTypeItem } from "../ui/DefectTypeSet";
import { WorkTableRow } from "./WorkTableRow";
import { SocketData } from "./context/WorkProvider";

type PageKeyType = 'equipment' | 'plan' | 'operationOutsource';

type ItemType<T extends PageKeyType> = T extends 'equipment'
    ? AuthSignupPost201ResponseEquipmentWorksInner : T extends 'plan' ? ProductionPlansGet200ResponseRowsInnerWorksInner : WorksItemGet200ResponseRowsInner;

interface WorkPassAreaProps<T extends PageKeyType> {
  rowData: ItemType<T>;
  pageKey: PageKeyType;
  socketData?: SocketData;
  socket?: any;
  defectTypes: DefectTypeItem[] | null;
  bgColor?: string;
  formReset: boolean;
}

export const WorkPassArea = <T extends PageKeyType>({
  rowData,
  pageKey,
  defectTypes,
  bgColor,
  socketData,
  socket,
  formReset,
}: WorkPassAreaProps<T>) => {
  const [socketCounter, setSocketCounter] = useState(
    socketData?.activeWorkId === rowData.id ? socketData?.equipmentCounter : null,
  );

  useEffect(() => {
    if (socketData?.activeWorkId === rowData.id) {
      setSocketCounter(socketData?.equipmentCounter);
    }
  }, [socketData, rowData.id, socketCounter]);
  return (
    <WorkTableRow<typeof pageKey>
      pageKey={pageKey}
      socketCounter={socketCounter}
      rowData={rowData}
      defectTypes={defectTypes}
      formReset={formReset}
    />
  );
};

import { customFunctions } from "@/config/customFunction";
import { t } from "i18next";

export interface ColumnHeaderProps {
  label: string;
  value: string;
  category: string;
  isFK: boolean;
  isEnum?: boolean;
}

export const itemsHeader: ColumnHeaderProps[] = [
  { label: t("item.name"), value: "name", category: "text", isFK: false },
  { label: t("item.code"), value: "code", category: "text", isFK: false },
  ...(customFunctions.ADD_STANDARD_INFO_GRADE_MENU_SETTING
    ? [{ label: t("item.gradeName"), value: "gradeName", category: "text", isFK: false }]
    : []),
  { label: t("item.type"), value: "itemType", category: "text", isFK: false, isEnum: true },
  { label: t("category.category"), value: "itemCategoryName", category: "text", isFK: true },
  { label: t("abstractItem.code"), value: "abstractItemCode", category: "text", isFK: true },
  { label: t("item.spec"), value: "spec", category: "text", isFK: false },
  { label: t("item.height"), value: "height", category: "number", isFK: false },
  { label: t("item.depth"), value: "depth", category: "number", isFK: false },
  { label: t("item.width"), value: "width", category: "number", isFK: false },
  { label: t("item.texture"), value: "texture", category: "text", isFK: false },
  { label: t("item.unit"), value: "unitText", category: "text", isFK: false },
];
export const equipmentsHeader: ColumnHeaderProps[] = [
  { label: t("site.code"), value: "siteCode", category: "text", isFK: true },
  { label: t("equipment.code"), value: "code", category: "text", isFK: false },
  { label: t("equipment.name"), value: "name", category: "text", isFK: false },
  { label: t("equipment.type"), value: "equipmentType", category: "text", isFK: false },
];
export const defectsHeader: ColumnHeaderProps[] = [
  { label: t("defect.code"), value: "code", category: "text", isFK: false },
  { label: t("defect.name"), value: "name", category: "text", isFK: false },
  { label: t("operation.code"), value: "operationCode", category: "text", isFK: true },
];
export const operationsHeader: ColumnHeaderProps[] = [
  { label: t("operation.code"), value: "code", category: "text", isFK: false },
  { label: t("site.code"), value: "siteCode", category: "text", isFK: true },
  { label: t("operation.name"), value: "name", category: "text", isFK: false },
];
export const routingsHeader: ColumnHeaderProps[] = [
  // { label: "품목명", value: "itemName", category: "text" },
  { label: t("item.code"), value: "itemCode", category: "text", isFK: false },
  // { label: "규격", value: "spec", category: "text" },
  { label: t("operation.code"), value: "operationCode", category: "text", isFK: true },
  { label: t("routing.code"), value: "code", category: "text", isFK: false },
  { label: t("routing.type"), value: "routingType", category: "text", isFK: false, isEnum: true },
  { label: t("mold.code"), value: "moldCode", category: "text", isFK: true },
];
export const usersHeader: ColumnHeaderProps[] = [
  { label: t("user.code"), value: "code", category: "text", isFK: false },
  { label: t("user.name"), value: "name", category: "text", isFK: false },
  { label: t("user.username"), value: "username", category: "text", isFK: false },
  { label: t("equipment.code"), value: "equipmentCode", category: "text", isFK: true },
  { label: t("location.code"), value: "locationCode", category: "text", isFK: false },
  { label: t("department.code"), value: "departmentCode", category: "text", isFK: false },
  { label: t("user.printer"), value: "printerCode", category: "text", isFK: false },
];
export const sitesHeader: ColumnHeaderProps[] = [
  { label: t("site.code"), value: "code", category: "text", isFK: false },
  { label: t("site.name"), value: "name", category: "text", isFK: false },
];
export const locationsHeader: ColumnHeaderProps[] = [
  { label: t("location.code"), value: "code", category: "text", isFK: false },
  { label: t("location.name"), value: "name", category: "text", isFK: false },
  { label: t("site.code"), value: "siteCode", category: "text", isFK: true },
];
export const routingOutsourcesHeader: ColumnHeaderProps[] = [
  { label: t("outsource.name"), value: "name", category: "text", isFK: false },
  { label: t("routing.code"), value: "routingCode", category: "text", isFK: true },
  {
    label: t("outsource.fromLocationCode"),
    value: "fromLocationCode",
    category: "text",
    isFK: true,
  },
  { label: t("outsource.toLocationCode"), value: "toLocationCode", category: "text", isFK: true },
];

export const inventoriesHeader: ColumnHeaderProps[] = [
  { label: t("item.code"), value: "itemCode", category: "text", isFK: false },
  { label: t("item.type"), value: "itemType", category: "text", isFK: false, isEnum: true },
  { label: t("lot.name"), value: "lotName", category: "text", isFK: false },
  // { label: "로트 유효기한", value: "expiration", category: "date" },
  { label: t("location.location"), value: "locationCode", category: "text", isFK: true },
  { label: t("inventory.addQuantity"), value: "increaseQuantity", category: "number", isFK: false },
  {
    label: t("inventory.subtractQuantity"),
    value: "decreaseQuantity",
    category: "number",
    isFK: false,
  },
  { label: t("inventory.changeQuantity"), value: "deltaQuantity", category: "number", isFK: false },
  { label: t("inventory.totalQuantity"), value: "quantity", category: "number", isFK: false },
  // { label: t("inventory.closeAt"), value: "closedAt", category: "date", isFK: false },
  { label: t("inventory.closeQuantity"), value: "closedQuantity", category: "number", isFK: false },
];

export const wipInventoriesHeader: ColumnHeaderProps[] = [
  { label: t("item.code"), value: "itemCode", category: "text", isFK: false },
  { label: t("location.location"), value: "locationCode", category: "text", isFK: true },
  // { label: "재고 라우팅단계", value: "routingStep", category: "text" },
  { label: "재고 수량", value: "quantity", category: "number", isFK: false },
];
export const LedgersHeader: ColumnHeaderProps[] = [
  { label: t("item.code"), value: "itemCode", category: "text", isFK: false },
  { label: t("location.location"), value: "locationCode", category: "text", isFK: true },
  // { label: "사업장", value: "locationData.site_code", category: "text", isFK: true },
  { label: t("user.user"), value: "userCode", category: "text", isFK: true },
  { label: t("lot.name"), value: "lotData.name", category: "text", isFK: false },
  { label: t("lot.lotExpiration"), value: "lotData.expiration", category: "date", isFK: false },
  { label: t("inventory.totalQuantity"), value: "quantity", category: "number", isFK: false },
];

export const moldsHeader: ColumnHeaderProps[] = [
  { label: t("mold.code"), value: "code", category: "text", isFK: false },
  { label: t("site.code"), value: "siteCode", category: "text", isFK: true },
  { label: t("mold.capacity"), value: "capacity", category: "number", isFK: false },
];

export const moldStocksHeader: ColumnHeaderProps[] = [
  { label: "금형 재고 코드", value: "code", category: "text", isFK: false },
  { label: t("mold.code"), value: "moldCode", category: "text", isFK: true },
];

export const abstractItemsHeader: ColumnHeaderProps[] = [
  { label: t("abstractItem.code"), value: "code", category: "text", isFK: false },
];

export const specificationsHeader: ColumnHeaderProps[] = [
  { label: t("item.code"), value: "itemCode", category: "text", isFK: false },
  // { label: "검사종류", value: "type", category: "text" },
  // { label: "검사 기준 요약", value: "criteria", category: "text" },
];

export const itemCategoriesHeader: ColumnHeaderProps[] = [
  { label: t("category.category"), value: "name", category: "text", isFK: false },
];

export const downtimeReasonsHeader: ColumnHeaderProps[] = [
  { label: "비가동사유코드", value: "code", category: "text", isFK: false },
  { label: "비가동사유명", value: "name", category: "text", isFK: false },
  { label: t("operation.code"), value: "operationCode", category: "text", isFK: true },
];

export const workLogHeader: ColumnHeaderProps[] = [
  { label: t("item.code"), value: "itemCode", category: "text", isFK: false },
  { label: "수량", value: "quantity", category: "number", isFK: false },
  {
    label: "투입 창고",
    value: "logData.incoming.locationData.name logData.outgoing.locationData.name",
    category: "text",
    isFK: true,
  },
  { label: "작업지시번호", value: "workId", category: "number", isFK: false },
  { label: "생산계획번호", value: "productionPlanId", category: "number", isFK: false },
  { label: "설비코드", value: "equipmentCode", category: "text", isFK: true },
  {
    label: "로트명",
    value: "logData.incoming.lotData.name logData.outgoing.lotData.name",
    category: "text",
    isFK: false,
  },
  // {
  //   label: "로트 유효기한",
  //   value: "logData.incoming.lotData.expiration logData.outgoing.lotData.expiration",
  //   category: "date",
  //   isFK: false,
  // },
  // { label: "생산일시", value: "createdAt", category: "date", isFK: false },
  { label: "작업자", value: "creatorUserCode", category: "text", isFK: false },
  ...(customFunctions.ADD_WORK_LOG_ERP_WORK_ORDER_COLUMN
    ? [{ label: "ERP 작업번호", value: "erpWorkOrderNo", category: "text", isFK: false }]
    : []),
];

export const purchaseOrderHeader: ColumnHeaderProps[] = [
  { label: "수주코드", value: "code", category: "text", isFK: false },
  { label: t("partner.code"), value: "partnerCode", category: "text", isFK: true },
  { label: "출고창고 명", value: "sourceLocationName", category: "text", isFK: true },
];

export const orderHeader: ColumnHeaderProps[] = [
  { label: "발주코드", value: "code", category: "text", isFK: false },
  { label: t("partner.code"), value: "partnerCode", category: "text", isFK: true },
  { label: "입고창고 명", value: "targetLocationName", category: "text", isFK: true },
];
export const purchaseOrderItemsHeader: ColumnHeaderProps[] = [
  { label: "수주 접수일", value: "orderDate", category: "date", isFK: false },
  { label: "납기일", value: "deliveryDeadline", category: "date", isFK: false },
  { label: t("item.code"), value: "itemCode", category: "text", isFK: false },
  { label: "수주량", value: "quantityOrdered", category: "number", isFK: false },
  { label: "업체명", value: "contractorName", category: "text", isFK: false },
];

export const deliveryStatusHeader: ColumnHeaderProps[] = [
  { label: "수주번호", value: "purchaseOrderItemId", category: "number", isFK: true },
  { label: t("item.code"), value: "itemCode", category: "text", isFK: false },
  { label: "위치", value: "locationCode", category: "text", isFK: true },
  { label: "납품날짜", value: "deliveryDeadline", category: "date", isFK: false },
  { label: "총 납품수량", value: "contractorName", category: "text", isFK: false },
];

export const wmsLogsHeader: ColumnHeaderProps[] = [
  { label: "수주번호", value: "purchaseOrderItemId", category: "number", isFK: false },
  // { label: "출고창고", value: "actionParam.params.sourceLocationCode actionParam.params.locationCode", category: "text", isFK: false },
  // { label: "입고창고", value: "actionParam.params.targetLocationCode actionParam.params.locationCode", category: "text", isFK: false },
  // { label: "작업자", value: "creatorUserCode", category: "text", isFK: false },
];

export const moldMaintenancesHeader: ColumnHeaderProps[] = [
  { label: "금형코드", value: "moldCode", category: "text", isFK: true },
  { label: "금형명", value: "moldName", category: "text", isFK: false },
];

export const itemChangesHeader: ColumnHeaderProps[] = [
  { label: t("item.code"), value: "itemCode", category: "text", isFK: false },
  // { label : "품목명", value : "itemName", category : "text", isFK : false },
  // { label : "규격", value : "itemSpec", category : "text", isFK : false },
  { label: "제목", value: "title", category: "text", isFK: false },
  { label: "작성자", value: "creatorUserCode", category: "text", isFK: false },
];

export const preWarehouseListHeader: ColumnHeaderProps[] = [
  { label: t("item.code"), value: "actionParam.params.itemCode", category: "text", isFK: false },
  // { label: "품목코드", value: "actionParam.params.itemCode", category: "text", isFK: true },
  // { label: "로트명", value: "actionParam.params?.lotInfo?.name", category: "text", isFK: false },
];

export const systemAccessLogHeader: ColumnHeaderProps[] = [
  { label: "동작", value: "action", category: "text", isFK: false },
  { label: "회사", value: "company", category: "text", isFK: false },
  { label: "날짜", value: "logDate", category: "date", isFK: false },
  { label: "유저", value: "user", category: "text", isFK: false },
];

export const systemParameterHeader: ColumnHeaderProps[] = [
  { label: "시스템 파라미터 키 (필수)", value: "systemKey", category: "text", isFK: false },
  { label: "시스템 파라미터 값 (필수)", value: "systemValue", category: "text", isFK: false },
  { label: "비고", value: "description", category: "text", isFK: false },
];

export const commonCodeHeader: ColumnHeaderProps[] = [
  { label: "공통코드", value: "commonCode", category: "text", isFK: false },
  { label: "코드명", value: "codeName", category: "text", isFK: false },
];

export const departmentHeader: ColumnHeaderProps[] = [
  { label: t("department.code"), value: "code", category: "text", isFK: false },
  { label: t("department.name"), value: "name", category: "text", isFK: false },
];

export const userLogsHeader: ColumnHeaderProps[] = [
  { label: "사용자명", value: "username", category: "text", isFK: false },
  { label: "아이피", value: "userIp", category: "text", isFK: false },
  { label: "액션", value: "action", category: "text", isFK: false },
  { label: "로그인 시간", value: "createdAt", category: "date", isFK: false },
];

export const bomHeader: ColumnHeaderProps[] = [
  { label: t("partner.code"), value: "partnerCode", category: "text", isFK: false },
  { label: "모델명", value: "modelName", category: "text", isFK: false },
  { label: "BOM코드", value: "bomCode", category: "text", isFK: false },
  { label: t("item.name"), value: "itemName", category: "text", isFK: false },
  { label: "규격", value: "spec", category: "text", isFK: false },
  { label: "품목유형", value: "itemType", category: "text", isFK: false },
  { label: "단위", value: "unitText", category: "text", isFK: false },
  { label: t("operation.code"), value: "operationCode", category: "text", isFK: false },
];

export const documentFileHeader: ColumnHeaderProps[] = [
  { label: "파일명", value: "fileName", category: "text", isFK: false },
  { label: "파일형식", value: "fileType", category: "text", isFK: false },
  { label: "파일크기", value: "fileSize", category: "text", isFK: false },
  { label: "등록일", value: "createdAt", category: "text", isFK: false },
];

export const shipmentStatusHeader: ColumnHeaderProps[] = [
  { label: "CODE", value: "code", category: "text", isFK: false },
  { label: t("item.name"), value: "itemName", category: "text", isFK: false },
  { label: t("item.spec"), value: "spec", category: "text", isFK: false },
  { label: "Serial 번호", value: "serialNumber", category: "text", isFK: false },
  { label: "거래처", value: "partnerCode", category: "text", isFK: false },
  { label: "날짜", value: "date", category: "text", isFK: false },
  { label: "입고량", value: "quantity", category: "text", isFK: false },
  { label: "단가", value: "price", category: "text", isFK: false },
  { label: "금액", value: "amount", category: "text", isFK: false },
  { label: "창고", value: "locationCode", category: "text", isFK: false },
  { label: "총 금액", value: "totalAmount", category: "text", isFK: false },
  { label: "상태", value: "status", category: "text", isFK: false },
];

export const lackSafetyHeader: ColumnHeaderProps[] = [
  { label: t("item.name"), value: "itemName", category: "text", isFK: false },
  { label: t("item.code"), value: "itemCode", category: "text", isFK: false },
  { label: t("item.spec"), value: "spec", category: "text", isFK: false },
  { label: "창고/공정명", value: "locationName", category: "text", isFK: false },
  { label: "LOTNO", value: "lotNo", category: "text", isFK: false },
  { label: "LOTDATE", value: "lotDate", category: "text", isFK: false },
  { label: "안전재고", value: "safetyStock", category: "text", isFK: false },
  { label: "현재고", value: "currentStock", category: "text", isFK: false },
  { label: "과부족량", value: "imbalance", category: "text", isFK: false },
  { label: "비고", value: "description", category: "text", isFK: false },
];

export const inventoryLifeHeader: ColumnHeaderProps[] = [
  { label: t("item.name"), value: "itemName", category: "text", isFK: false },
  { label: t("item.code"), value: "itemCode", category: "text", isFK: false },
  { label: t("item.spec"), value: "spec", category: "text", isFK: false },
  { label: "창고/공정명", value: "locationName", category: "text", isFK: false },
  { label: "LOTNO", value: "lotNo", category: "text", isFK: false },
  { label: "LOTDATE", value: "lotDate", category: "text", isFK: false },
  { label: "Serial 번호", value: "serialNumber", category: "text", isFK: false },
  { label: "현재고", value: "currentStock", category: "text", isFK: false },
  { label: "남은 유효일수", value: "deadLine", category: "text", isFK: false },
  { label: "비고", value: "description", category: "text", isFK: false },
];

export const peripheralHeader: ColumnHeaderProps[] = [
  { label: "제조일", value: "manufacturedAt", category: "text", isFK: false },
  { label: "장치 제조사", value: "manufactureCompany", category: "text", isFK: false },
  { label: "장치명", value: "modelName", category: "text", isFK: false },
  { label: "장치유형", value: "modelType", category: "text", isFK: false },
  { label: "제조번호", value: "manufactureId", category: "text", isFK: false },
  { label: "담당자", value: "userName", category: "text", isFK: false },
  { label: "공장명", value: "factoryName", category: "text", isFK: false },
  { label: "공장상세명", value: "factoryDetailName", category: "text", isFK: false },
];

export const machineHeader: ColumnHeaderProps[] = [
  { label: "기계 제조사", value: "machineCompany", category: "text", isFK: false },
  { label: "기계명", value: "machineName", category: "text", isFK: false },
  { label: "기계유형", value: "machineType", category: "text", isFK: false },
  { label: "제조일", value: "manufacturedAt", category: "text", isFK: false },
  { label: "제조번호", value: "manufactureId", category: "text", isFK: false },
  { label: "담당자", value: "userName", category: "text", isFK: false },
  { label: "오버홀", value: "isOverHall", category: "text", isFK: false },
  { label: "공장명", value: "factoryName", category: "text", isFK: false },
  { label: "공장상세명", value: "factoryDetailName", category: "text", isFK: false },
];

export const gradesHeader: ColumnHeaderProps[] = [
  { label: "등급코드", value: "code", category: "text", isFK: false },
  { label: "등급명", value: "name", category: "text", isFK: false },
];

export const partnersHeader: ColumnHeaderProps[] = [
  { label: t("partner.code"), value: "code", category: "text", isFK: false },
  { label: t("partner.name"), value: "name", category: "text", isFK: false },
  { label: t("partner.contact"), value: "contact", category: "text", isFK: false },
];

export const invoiceStatementHeader: ColumnHeaderProps[] = [
  { label: t("invoice.code"), value: "code", category: "text", isFK: false },
  { label: t("location.code"), value: "locationCode", category: "text", isFK: false },
  { label: t("user.code"), value: "creatorUserCode", category: "text", isFK: false },
];

export const itemsBomHeader: ColumnHeaderProps[] = [
  { label: t("item.name"), value: "name", category: "text", isFK: false },
  { label: t("item.code"), value: "code", category: "text", isFK: false },
  ...(customFunctions.ADD_STANDARD_INFO_GRADE_MENU_SETTING
    ? [{ label: "Grade명", value: "gradeName", category: "text", isFK: false }]
    : []),
  { label: t("item.type"), value: "itemType", category: "text", isFK: false, isEnum: true },
  // { label: "품목분류", value: "itemCategoryName", category: "text", isFK: true },
  // { label: "품목군", value: "abstractItemCode", category: "text", isFK: true },
];

export const lotsHeader: ColumnHeaderProps[] = [
  { label: "로트명", value: "name", category: "text", isFK: false },
  { label: "아이템코드", value: "itemCode", category: "text", isFK: false },
  { label: "생성일", value: "createdAt", category: "date", isFK: false },
  { label: "수정일", value: "updatedAt", category: "date", isFK: false },
];

import { Flex, MultiSelectProps, Text } from "@mantine/core";
import { forwardRef, useMemo, useState } from "react";

import { FKInfoLabel, FKMultiSelect } from "@/features/ui/Base/List/CustomFilter/MultiField";
import { IconX } from "@tabler/icons-react";
import {
  useEquipmentsMultiAutoComplete,
  useEquipmentsMultiCode,
} from "./multiAuto-useGetEquipmentsQuery";

interface EquipmentMultiProps extends React.ComponentPropsWithoutRef<"div"> {
  label: string;
  value: string;
}

export interface EquipmentsMultiAutoCompleteProps
  extends Partial<MultiSelectProps>,
    Partial<React.RefAttributes<HTMLInputElement>> {
  value: string[];
  onChange?: (e: string[]) => void; // (value: string[]) => React.Dispatch<React.SetStateAction<string>>;
  maxDropdownHeight?: number;
  width?: string;
}

export const EquipmentsMultiAutoComplete = (params: EquipmentsMultiAutoCompleteProps) => {
  const { value: equipments, onChange, maxDropdownHeight, width, ...etcParams } = params;
  const [focused, setFocused] = useState<boolean>(true);
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const { data: options } = useEquipmentsMultiAutoComplete(focused, searchKeyword);
  const { data: initialOptions } = useEquipmentsMultiCode(!!searchKeyword, searchKeyword ?? null);

  const onChangeHandler = (e: string[]) => {
    onChange && onChange(e);
  };

  const SelectEquipment = forwardRef<HTMLDivElement, EquipmentMultiProps>(
    ({ label: name, value: code, ...others }, ref) => (
      <div ref={ref} {...others}>
        <Flex direction="row" justify="space-between" align="center">
          <Flex align={"center"}>
            <Text>{name}</Text>
            <Text fz="xs">(품목분류명: {code})</Text>
          </Flex>
        </Flex>
      </div>
    ),
  );

  // data prop에 들어가는 중복 제거된 data를 useMemo로 감싸기
  const filteredOptions = useMemo(() => {
    const combinedOptions = [
      ...(options ?? []),
      ...(initialOptions ?? []),
      ...(equipments.map((value) => ({ label: value, value })) ?? []),
    ];

    return combinedOptions.reduce(
      (unique: EquipmentMultiProps[], option: EquipmentMultiProps) =>
        unique.some((u) => u.value === option.value) ? unique : [...unique, option],
      [],
    );
  }, [options, initialOptions, equipments]);

  return (
    <FKMultiSelect
      clearable
      value={equipments}
      data={filteredOptions}
      onChange={onChangeHandler}
      searchable
      itemComponent={SelectEquipment}
      searchValue={searchKeyword}
      withinPortal
      onDropdownOpen={() => setFocused(true)}
      onDropdownClose={() => setFocused(false)}
      maxDropdownHeight={maxDropdownHeight ?? 150}
      onSearchChange={setSearchKeyword}
      nothingFound="No Options"
      rightSection={EquipmentInfo({
        Equipment: equipments,
        onChange: onChangeHandler,
      })}
      {...etcParams}
    />
  );
};

const EquipmentInfo = (params: { Equipment?: string[]; onChange: (e: string[]) => void }) => {
  const { Equipment, onChange } = params;

  const clearHandler: React.MouseEventHandler<SVGSVGElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onChange([]);
  };

  return Equipment?.length ? (
    <FKInfoLabel>
      <IconX size="1rem" onClick={clearHandler} />
    </FKInfoLabel>
  ) : null;
};

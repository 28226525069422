import { customFunctions } from "@/config/customFunction";
import { useModal } from "@/features/modal/ModalStackManager";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { FormActionButtonGroup } from "@/features/ui/formActionButtonGroup/FormActionButtonGroup";
import { NewLotCreateInput } from "@/features/ui/newLotCreateInput/NewLotCreateInput";
import { MantineSelectableTable } from "@/features/ui/table/MantineSelectableTable";
import { useCreatePurchaseOrderItemsWithLot } from "@/hooks/useCreatePurchaseOrderItemsWithLot";
import styled from "@emotion/styled";
import { Flex, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import {
    AuthSignupPost201ResponseEquipmentPurchaseOrderItem,
    ItemsGet200ResponseRowsInnerLotsInner,
    PurchaseOrdersGet200ResponseRowsInner,
    PurchaseOrdersGet200ResponseRowsInnerPurchaseOrderItemsInnerItem,
} from "@sizlcorp/sizl-api-document/dist/models";
import { MRT_ColumnDef } from "mantine-react-table";
import { Dispatch, SetStateAction, useMemo } from "react";
import { useOrderItemsIncoming } from "../hook/useOrderItemsIncoming";

export interface PurchaseOrderItemsSelectFormProps {
  purchaseOrder: PurchaseOrdersGet200ResponseRowsInner | undefined;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

export type OrderItemType = AuthSignupPost201ResponseEquipmentPurchaseOrderItem & {
  lotId: number | undefined;
  lotInfo:
    | {
        name: string | undefined;
        expiration: string | undefined;
      }
    | undefined;
  item?: PurchaseOrdersGet200ResponseRowsInnerPurchaseOrderItemsInnerItem | undefined;
};

export type OrderItemsFormValuesType = {
  selectedItems: OrderItemType[];
};

const OrderItemsIncoming = Object.assign(
  {},
  {
    TextInput: TextInput,
    NewLotCreateInput: NewLotCreateInput,
    LocationAutoComplete: LocationAutoComplete,
    FormActionButtonGroup: FormActionButtonGroup,
    MantineSelectableTable: MantineSelectableTable,
  },
);

export const OrderItemsIncomingForm = ({
  purchaseOrder,
  setLoading,
}: PurchaseOrderItemsSelectFormProps) => {
  const { closeModal } = useModal();
  const { orderItemsSubmit } = useOrderItemsIncoming({ setLoading });

  const { updatedPurchaseOrderItemsWithLot } = useCreatePurchaseOrderItemsWithLot({
    items: purchaseOrder?.purchaseOrderItems,
  });

  const updatedPurchaseOrderItems =
    customFunctions.ADD_ORDER_AUTO_CREATE_LOT &&
    purchaseOrder?.purchaseOrderItems?.find((item) => item.item?.usesLotName)
      ? updatedPurchaseOrderItemsWithLot
      : purchaseOrder?.purchaseOrderItems;

  const form = useForm<OrderItemsFormValuesType>({
    initialValues: {
      selectedItems: [
        {
          lotId: undefined,
          lotInfo: {
            name: undefined,
            expiration: undefined,
          },
          item: undefined,
          purchaseOrderItemQty: undefined,
        },
      ],
    },
  });

  const isItemSelected = !form.values.selectedItems?.[0]?.id;
  const usesLotName = form.values.selectedItems?.[0]?.item?.usesLotName;
  const usesLotExpiration = form.values.selectedItems?.[0]?.item?.usesLotExpiration;
  const isLotCreationEnabled = !usesLotName && !usesLotExpiration;

  const lotDescription = (() => {
    if (usesLotName && usesLotExpiration) return "이름 + 만료일";
    if (usesLotName) return "이름";
    if (usesLotExpiration) return "만료일";
    return "관리 안함";
  })();

  const columns = useMemo<MRT_ColumnDef[]>(() => {
    const baseColumns = [
      {
        accessorKey: "partnerCode",
        header: "협력사명",
      },
      {
        accessorKey: "itemCode",
        header: "품목명",
      },
      {
        accessorKey: "quantity",
        header: "수량",
      },
    ];

    if (
      customFunctions.ADD_ORDER_AUTO_CREATE_LOT &&
      purchaseOrder?.purchaseOrderItems?.find((item) => item.item?.usesLotName)
    ) {
      baseColumns.push(
        {
          accessorKey: "purchaseOrderItemQty",
          header: "분할개수",
        },
        {
          accessorKey: "lotName",
          header: "로트번호",
        },
      );
    } else if (customFunctions.ADD_ORDER_AUTO_CREATE_LOT) {
      baseColumns.push({
        accessorKey: "lotName",
        header: "로트번호",
      });
    }

    return baseColumns;
  }, [customFunctions.ADD_ORDER_AUTO_CREATE_LOT]);

  const handleRowSelectionChange = (selectedRows: any[]) => {
    form.setFieldValue("selectedItems", selectedRows);
  };

  const handleLotCreation = (data: ItemsGet200ResponseRowsInnerLotsInner) => {
    form.setFieldValue("selectedItems", [
      {
        ...form.values.selectedItems[0],
        lotId: data?.id as number,
        lotInfo: {
          name: data?.name,
          expiration: data?.expiration,
        },
      },
    ]);
  };

  const onSubmit = () => {
    for (const item of form.values?.selectedItems ?? []) {
      orderItemsSubmit(item);
    }
    closeModal(true);
  };

  return (
    <>
      {purchaseOrder?.purchaseOrderItems?.length === 0 ? (
        <Flex>
          <Text size="xl" fw="bold">
            발주항목이 없습니다. 발주항목을 생성해주세요.
          </Text>
        </Flex>
      ) : (
        <Flex direction={"column"} gap={30}>
          <OrderItemsIncoming.MantineSelectableTable
            data={updatedPurchaseOrderItems ?? []}
            columns={columns}
            onRowSelection={handleRowSelectionChange}
            enableMultiRowSelection={customFunctions.ADD_ORDER_AUTO_CREATE_LOT}
          />
          {!customFunctions.ADD_ORDER_AUTO_CREATE_LOT && (
            <>
              <OrderItemsIncoming.TextInput
                disabled
                label="품목코드"
                withAsterisk
                value={form.values.selectedItems?.[0]?.itemCode}
              />
              <OrderItemsIncoming.NewLotCreateInput
                lotDescription={lotDescription}
                disabled={isLotCreationEnabled}
                itemCode={form.values.selectedItems?.[0]?.itemCode as string}
                newIdValue={form.values.selectedItems?.[0]?.lotId as number}
                defaultValue={form.values.selectedItems?.[0]?.lotId?.toString() ?? ""}
                onLotCreated={handleLotCreation}
                {...form.getInputProps("selectedItems.0.lotId")}
              />
            </>
          )}
          <OrderItemsIncoming.LocationAutoComplete
            label="창고코드"
            withAsterisk
            defaultValue={purchaseOrder?.targetLocationCode}
            {...form.getInputProps("locationCode")}
          />
          {!customFunctions.ADD_ORDER_AUTO_CREATE_LOT && (
            <>
              <OrderItemsIncoming.TextInput
                label="수량"
                withAsterisk
                type="number"
                {...form.getInputProps("selectedItems.0.quantity")}
              />
              <OrderItemsIncoming.TextInput
                disabled
                label="만료일"
                value={form.values.selectedItems?.[0]?.lotInfo?.expiration}
              />
            </>
          )}
          <OrderItemsIncoming.FormActionButtonGroup
            disabled={isItemSelected}
            form={form}
            onCancel={() => closeModal({})}
            onSubmit={onSubmit}
          >
            {"입고"}
          </OrderItemsIncoming.FormActionButtonGroup>
        </Flex>
      )}
    </>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

import {
  AuthSignupPost201ResponseEquipmentWorksInner,
  ProductionPlansGet200ResponseRowsInnerWorksInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import dayjs from "dayjs";
import { createContext, useMemo, useState } from "react";

export interface SocketData {
  equipmentCounter: string | null;
  equipmentLimitCounter: string | null;
  isLimitHit: boolean;
  activeWorkId: string | null;
  workId?: number;
}

export interface ERPWorkDataProps {
  startWorkLogId?: number[] | undefined;
  endWorkLogId?: number[] | undefined;
  workIds?: number[] | undefined;
  OSPDelvDate?: string[] | undefined;
}

export interface ERPWorkDataProps {
  startWorkLogId?: number[] | undefined;
  endWorkLogId?: number[] | undefined;
  workIds?: number[] | undefined;
  OSPDelvDate?: string[] | undefined;
}

export interface ERPWorkDataProps {
  startWorkLogId?: number[] | undefined;
  endWorkLogId?: number[] | undefined;
  workIds?: number[] | undefined;
  OSPDelvDate?: string[] | undefined;
}

export interface ERPWorkDataProps {
  startWorkLogId?: number[] | undefined;
  endWorkLogId?: number[] | undefined;
  workIds?: number[] | undefined;
  OSPDelvDate?: string[] | undefined;
}

export interface ERPWorkDataProps {
  startWorkLogId?: number[] | undefined;
  endWorkLogId?: number[] | undefined;
  workIds?: number[] | undefined;
  OSPDelvDate?: string[] | undefined;
}

type tempData = { key: string; value: string; label?: string; operationCode?: string };
export interface WorkContextProps {
  site?: string;
  line?: string;
  date: [Date | null, Date | null]; // string;
  works: (AuthSignupPost201ResponseEquipmentWorksInner | ProductionPlansGet200ResponseRowsInnerWorksInner)[];
  erpWork: ERPWorkDataProps;
  tempWorks: (AuthSignupPost201ResponseEquipmentWorksInner | ProductionPlansGet200ResponseRowsInnerWorksInner)[];
  tempQuantity: tempData[];
  tempDefects: { id?: string; defect: tempData[] }[];
  equipmentCode?: string;
  itemCode?: string;
  erpWorkData: ERPWorkDataProps;
  workDate: [Date | null, Date | null];
}

const initialDate = dayjs(new Date()).startOf("day").toDate();

// 초기 상태에 대한 타입 안정성 강화
const initialWorkState: WorkContextProps = {
  site: undefined,
  line: undefined,
  date: [initialDate, initialDate],
  works: [],
  erpWork: {
    startWorkLogId: [],
    endWorkLogId: [],
    workIds: [],
    OSPDelvDate: []
  },
  tempWorks: [],
  tempQuantity: [],
  tempDefects: [],
  equipmentCode: "",
  itemCode: "",
  erpWorkData: {
    startWorkLogId: [],
    endWorkLogId: [],
    workIds: [],
    OSPDelvDate: [],
  },
  workDate: [initialDate, initialDate],
};

export const WorkStateContext = createContext<WorkContextProps>(initialWorkState);

export const WorkActionsContext = createContext<{
  setResetState: () => void;
  setSite: (site?: string) => void;
  setLine: (line?: string) => void;
  setDate: (date: [Date | null, Date | null]) => void; // 여러 날짜를 설정할 수 있도록 배열로 변경
  setWorks: (
    works: (
      prevWorks: (
        | AuthSignupPost201ResponseEquipmentWorksInner
        | ProductionPlansGet200ResponseRowsInnerWorksInner
      )[],
    ) => (
      | AuthSignupPost201ResponseEquipmentWorksInner
      | ProductionPlansGet200ResponseRowsInnerWorksInner
    )[],
  ) => void;
  setTempWorks: (
    works: (
      prevWorks: (
        | AuthSignupPost201ResponseEquipmentWorksInner
        | ProductionPlansGet200ResponseRowsInnerWorksInner
      )[],
    ) => (
      | AuthSignupPost201ResponseEquipmentWorksInner
      | ProductionPlansGet200ResponseRowsInnerWorksInner
    )[],
  ) => void;
  setTempQuantity: (quantity: tempData[]) => void;
  setTempDefects: (defects: { id?: string; defect: tempData[] }[]) => void;
  setEquipment: (equipmentCode: string | null) => void;
  setItem: (itemCode: string | null) => void;
  setErpWorkData: (erpWork: (prev: ERPWorkDataProps) => ERPWorkDataProps) => void;
  setWorkDate: (date: [Date | null, Date | null]) => void;
}>({
  setResetState: () => {},
  setSite: () => {},
  setLine: () => {},
  setDate: () => {},
  setWorks: () => {},
  setTempWorks: () => {},
  setTempQuantity: () => {},
  setTempDefects: () => {},
  setEquipment: () => {},
  setItem: () => {},
  setErpWorkData: () => {},
  setWorkDate: () => {},
});

export const WorkProvider = ({ children }: { children: React.ReactNode }) => {
  const [workState, setWorkState] = useState<WorkContextProps>(initialWorkState);

  const actions = useMemo(
    () => ({
      setResetState: () => {
        setWorkState((prevState) => ({
          ...initialWorkState,
          date: prevState.date, // date는 초기화하지 않음
        }));
      },
      setDate: (date: [Date | null, Date | null]) => {
        setWorkState((prev) => ({
          ...prev,
          date,
        }));
      },
      setSite: (site?: string) => {
        setWorkState((prev) => ({
          ...prev,
          site,
        }));
      },
      setLine: (line?: string) => {
        setWorkState((prev) => ({
          ...prev,
          line,
        }));
      },
      setWorks: (
        works: (
          prevWorks: (
            | AuthSignupPost201ResponseEquipmentWorksInner
            | ProductionPlansGet200ResponseRowsInnerWorksInner
          )[],
        ) => (
          | AuthSignupPost201ResponseEquipmentWorksInner
          | ProductionPlansGet200ResponseRowsInnerWorksInner
        )[],
      ) => {
        setWorkState((prev) => ({
          ...prev,
          works: works(prev.works),
        }));
      },
      setTempWorks: (
        tempWorks: (
          prevWorks: (
            | AuthSignupPost201ResponseEquipmentWorksInner
            | ProductionPlansGet200ResponseRowsInnerWorksInner
          )[],
        ) => (
          | AuthSignupPost201ResponseEquipmentWorksInner
          | ProductionPlansGet200ResponseRowsInnerWorksInner
        )[],
      ) => {
        setWorkState((prev) => ({
          ...prev,
          tempWorks: tempWorks(prev.tempWorks),
        }));
      },
      setTempQuantity: (quantity: tempData[]) => {
        setWorkState((prev) => ({
          ...prev,
          tempQuantity: quantity,
        }));
      },
      setTempDefects: (defects: { id?: string; defect: tempData[] }[]) => {
        setWorkState((prev) => ({
          ...prev,
          tempDefects: defects,
        }));
      },
      setEquipment: (equipmentCode: string | null) => {
        setWorkState((prev: any) => ({
          ...prev,
          equipmentCode,
        }));
      },
      setItem: (itemCode: string | null) => {
        setWorkState((prev: any) => ({
          ...prev,
          itemCode,
        }));
      },
      setErpWorkData: (erpWork: (prev: ERPWorkDataProps) => ERPWorkDataProps) => {
        setWorkState((prev) => ({
          ...prev,
          erpWorkData: erpWork(prev.erpWorkData),
        }));
      },
      setWorkDate: (date: [Date | null, Date | null]) => {
        setWorkState((prev) => ({
          ...prev,
          workDate: date,
        }));
      },
    }),
    [],
  );

  return (
    <WorkActionsContext.Provider value={actions}>
      <WorkStateContext.Provider value={workState}>{children}</WorkStateContext.Provider>
    </WorkActionsContext.Provider>
  );
};

// @fileoverview : 재고관리 > 재고현황

import { Avatar, Badge, Flex, Text, Tooltip } from "@mantine/core";
import { useModal } from "@/features/modal/ModalStackManager";
import { IconFilterPlus } from "@tabler/icons-react";
import { customFunctions } from "@/config/customFunction";
import { itemTypeColor } from "@/constants/items";
import { DateHeader } from "@/features/inventory/components/DateHeader";
import { LedgerDetailForm } from "@/features/inventory/components/Form/detail/DetailForm";
import { ActionHeader } from "@/features/inventory/components/InventoryActionHeader";
import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { LocationDetailForm } from "@/features/location/detail/LocationDetailForm";
import { useStandardLayout } from "@/features/standardLayout/Context";
import { Filter } from "@/features/standardLayout/Filter";
import { LinkAnchor } from "@/features/standardLayout/LinkAnchor";
import { ListTable } from "@/features/standardLayout/ListTable";
import { PaginationBox } from "@/features/standardLayout/PaginationBox";
import { SearchBox } from "@/features/standardLayout/SearchBox";
import { Sorter } from "@/features/standardLayout/Sorter";
import {
  flexGrowable,
  flexStable,
  StandardLayoutTableWrapper,
} from "@/features/standardLayout/Styles";
import timeUtil from "@/utils/timeUtil";
import { setToLocaleString } from "@/utils/unitMark";
import {
  InventoriesBetweenGet200Response,
  InventoriesBetweenGet200ResponseRowsInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import dayjs from "dayjs";
import { useEffect, useMemo } from "react";
import { Column, SelectColumn } from "react-data-grid";
import { useTranslation } from "react-i18next";

export const InventoryPage = () => {
  const { openModal, closeModal } = useModal();
  const { t } = useTranslation();
  const { sort, setSort, endDate, setParams, startDate } = useStandardLayout();

  useEffect(() => {
    const updatedParams = {
      targetDateString: dayjs(startDate).startOf("day").toISOString(),
      targetDateEndString: dayjs(endDate).endOf("day").toISOString(),
    };

    setParams((prev) => ({
      ...prev,
      ...updatedParams,
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  useEffect(() => {
    // 경남케미칼 커스텀
    if (customFunctions.ADD_INVENTORY_QUANTITY_SORT_FIX && !sort.length) {
      setSort(() => ["-quantity"]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort]);

  const columns: readonly Column<InventoriesBetweenGet200ResponseRowsInner>[] = useMemo(
    () => [
      {
        ...SelectColumn,
        resizable: true,
      },
      {
        key: "itemCode",
        name: t("item.code"),
        sortable: true,
        resizable: true,
        formatter: ({ row }: any) => {
          const detailItemAction = () => {
            openModal(
              <ItemsDetailForm itemCode={row.itemCode} />,
              null,
              `${t("common.detail", { item: `${t("item.item")}` })}`,
            );
          };
          return <LinkAnchor onClick={detailItemAction}>{row.itemCode}</LinkAnchor>;
        },
      },
      {
        key: "itemName",
        name: t("item.name"),
        sortable: true,
        resizable: true,
      },
      {
        key: "spec",
        name: t("item.spec"),
        sortable: true,
        resizable: true,
      },
      {
        key: "itemType",
        name: t("item.type"),
        sortable: true,
        resizable: true,
        formatter: ({ row }) => {
          return (
            <Badge size="lg" color={itemTypeColor[row?.itemType as keyof typeof itemTypeColor]}>
              {t(row?.itemType as string)}
            </Badge>
          );
        },
      },
      {
        key: "lot.name",
        name: t("lot.name"),
        sortable: true,
        resizable: true,
        formatter: ({ row }) => <Flex align="center">{row.lot?.name}</Flex>,
      },
      {
        key: "lot.expiration",
        name: t("lot.lotExpiration"),
        sortable: true,
        resizable: true,
        formatter: ({ row }) => (
          <Flex align="center">
            {row.lot?.expiration === null ? "" : timeUtil(row.lot?.expiration!)}
          </Flex>
        ),
      },
      {
        key: "locationName",
        name: t("location.name"),
        sortable: true,
        resizable: true,
        formatter: ({ row }) => {
          const detailItemAction = () => {
            openModal(
              <LocationDetailForm locationCode={row.locationCode ?? ""} />,
              null,
              `${t("common.detail", { item: `${t("location.location")}` })}`,
            );
          };
          return <LinkAnchor onClick={detailItemAction}>{row.locationName}</LinkAnchor>;
        },
      },
      {
        key: "createdAt",
        name: t("inventory.incomingAt"),
        sortable: true,
        resizable: true,
        formatter: ({ row }) => {
          return <Flex align="center">{timeUtil(row?.createdAt as string)}</Flex>;
        },
      },
      {
        key: "closedQuantity",
        name: t("inventory.closeQuantity"),
        sortable: true,
        resizable: true,
        formatter: ({ row }) => (
          <Flex justify="flex-end">
            {row.closedQuantity !== null
              ? setToLocaleString(row?.closedQuantity) + " " + row.unitText
              : ""}
          </Flex>
        ),
      },
      {
        key: "closedAt",
        name: t("inventory.closeAt"),
        sortable: true,
        resizable: true,
        width: 160,
        formatter: ({ row }) => {
          return (
            <Flex align="center">
              {row?.closedAt === null ? "" : dayjs(row.closedAt).format("YYYY-MM-DD HH:mm:ss")}
            </Flex>
          );
        },
      },
      {
        key: "increaseQuantity",
        name: t("inventory.addQuantity"),
        sortable: true,
        resizable: true,
        formatter: ({ row }) => {
          const betweenIncreaseQuantity = row?.betweenIncreaseQuantity ?? 0;
          return (
            <Flex justify="flex-end">
              {setToLocaleString(betweenIncreaseQuantity)} {row.unitText}
            </Flex>
          );
        },
      },
      {
        key: "decreaseQuantity",
        name: t("inventory.subtractQuantity"),
        sortable: true,
        resizable: true,
        formatter: ({ row }) => {
          const betweenDecreaseQuantity = row?.betweenDecreaseQuantity ?? 0;
          return (
            <Flex justify="flex-end">
              {setToLocaleString(betweenDecreaseQuantity)} {row.unitText}
            </Flex>
          );
        },
      },
      {
        key: "deltaQuantity",
        name: t("inventory.changeQuantity"),
        sortable: true,
        resizable: true,
        formatter: ({ row }) => {
          const betweenDeltaQuantity = row?.betweenDeltaQuantity ?? 0;
          return (
            <Flex justify="flex-end">
              {setToLocaleString(betweenDeltaQuantity)} {row.unitText}
            </Flex>
          );
        },
      },
      {
        key: "quantityAtStart",
        name: t("inventory.carryOverQuantity"),
        sortable: true,
        resizable: true,
        formatter: ({ row }) => {
          const quantityAtStart = row?.quantityAtStart ?? 0;
          return (
            <Flex justify="flex-end">
              {setToLocaleString(quantityAtStart)} {row.unitText}
            </Flex>
          );
        },
      },
      {
        key: "quantity",
        name: t("inventory.totalQuantity"),
        sortable: true,
        resizable: true,

        formatter: ({ row }) => {
          const quantityAtEnd = row?.quantityAtEnd ?? 0;
          const detailItemAction = () => {
            openModal(
              <LedgerDetailForm data={row} />,
              null,
              `${t("common.detail", { item: `${t("inventory.inventory")}` })}`,
            );
          };
          return (
            <Flex h="100%" justify="flex-end" align="center">
              <LinkAnchor onClick={detailItemAction}>
                {setToLocaleString(quantityAtEnd)} {row.unitText}
              </LinkAnchor>
            </Flex>
          );
        },
      },
    ],
    [openModal, t],
  );

  return (
    <Flex w="100%" h="100%" gap="xs" direction="column">
      <ActionHeader />
      <DateHeader />
      <Flex bg="white" px="sm" direction="column" gap="xs">
        <Text fz="xl" fw={600}>
          {t("common.list", { item: `${t("inventory.inventory")}` })}
        </Text>
        <Text fz="xs">{t("common.subTitle", { item: `${t("inventory.inventory")}` })}</Text>
      </Flex>
      <Flex justify="space-between" align="flex-start" px="sm">
        <Flex wrap="wrap" styles={flexGrowable}>
          <Flex gap="xs" align="center" h="100%" wrap="wrap" direction="row">
            <Tooltip label={t("common.filterDescription")}>
              <Avatar color="blue" variant="outline">
                <IconFilterPlus />
              </Avatar>
            </Tooltip>
            <Flex direction="column" gap="xs" wrap="wrap">
              <Filter />
              <Sorter />
            </Flex>
          </Flex>
        </Flex>
        <Flex styles={flexStable}>
          <SearchBox />
        </Flex>
      </Flex>
      <Flex w="100%" h="100%" p="sm" justify="space-between" gap="md" direction="column">
        <StandardLayoutTableWrapper>
          <ListTable<InventoriesBetweenGet200Response, InventoriesBetweenGet200ResponseRowsInner>
            columns={columns}
            rowKeyGetter={(row: InventoriesBetweenGet200ResponseRowsInner) => JSON.stringify(row)}
          />
        </StandardLayoutTableWrapper>
        <PaginationBox<InventoriesBetweenGet200Response> />
      </Flex>
    </Flex>
  );
};

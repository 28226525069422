export const 품목 = [
  {
    id: 65,
    code: "PCERAV00 (new)",
    itemType: "SEMI_PRODUCT",
    name: "CERA (베트남완제품) (new)",
    unitText: "EA",
    itemCategoryName: null,
    abstractItemCode: null,
    description: null,
    spec: null,
    texture: null,
    height: null,
    width: null,
    depth: null,
    additional: null,
    usesLotName: true,
    usesLotExpiration: false,
    expirationDayFromProduction: null,
    standardUph: null,
    erpItemSeq: null,
    gradeCode: null,
    createdAt: "2024-12-26T01:16:42.261Z",
    updatedAt: "2024-12-26T01:16:42.261Z",
    creatorUserCode: "test",
    updaterUserCode: null,
  },
  {
    id: 64,
    code: "SCERAV10-SP (new)",
    itemType: "SEMI_PRODUCT",
    name: "CERA (베트남표면처리_AOI) (new)",
    unitText: "EA",
    itemCategoryName: null,
    abstractItemCode: null,
    description: null,
    spec: null,
    texture: null,
    height: null,
    width: null,
    depth: null,
    additional: null,
    usesLotName: true,
    usesLotExpiration: false,
    expirationDayFromProduction: null,
    standardUph: null,
    erpItemSeq: null,
    gradeCode: null,
    createdAt: "2024-12-26T01:14:31.993Z",
    updatedAt: "2024-12-26T01:14:31.993Z",
    creatorUserCode: "test",
    updaterUserCode: null,
  },
  {
    id: 63,
    code: "SCERAV10-EP (new)",
    itemType: "SEMI_PRODUCT",
    name: "CERA (베트남표면처리_에폭시) (new)",
    unitText: "EA",
    itemCategoryName: null,
    abstractItemCode: null,
    description: null,
    spec: null,
    texture: null,
    height: null,
    width: null,
    depth: null,
    additional: null,
    usesLotName: true,
    usesLotExpiration: false,
    expirationDayFromProduction: null,
    standardUph: null,
    erpItemSeq: null,
    gradeCode: null,
    createdAt: "2024-12-26T01:13:05.892Z",
    updatedAt: "2024-12-26T01:13:05.892Z",
    creatorUserCode: "test",
    updaterUserCode: null,
  },
  {
    id: 62,
    code: "SCERAV10-PPC (new)",
    itemType: "SEMI_PRODUCT",
    name: "CERA (베트남표면처리_인산염) (new)",
    unitText: "EA",
    itemCategoryName: null,
    abstractItemCode: null,
    description: null,
    spec: null,
    texture: null,
    height: null,
    width: null,
    depth: null,
    additional: null,
    usesLotName: true,
    usesLotExpiration: false,
    expirationDayFromProduction: null,
    standardUph: null,
    erpItemSeq: null,
    gradeCode: null,
    createdAt: "2024-12-26T01:11:09.499Z",
    updatedAt: "2024-12-26T01:11:09.499Z",
    creatorUserCode: "test",
    updaterUserCode: null,
  },
  {
    id: 60,
    code: "SCERAV20-SG (new)",
    itemType: "SEMI_PRODUCT",
    name: "CERA (베트남후가공_연마) (new)",
    unitText: "EA",
    itemCategoryName: null,
    abstractItemCode: null,
    description: null,
    spec: null,
    texture: null,
    height: null,
    width: null,
    depth: null,
    additional: null,
    usesLotName: true,
    usesLotExpiration: false,
    expirationDayFromProduction: null,
    standardUph: null,
    erpItemSeq: null,
    gradeCode: null,
    createdAt: "2024-12-26T01:07:29.361Z",
    updatedAt: "2024-12-26T01:07:29.361Z",
    creatorUserCode: "test",
    updaterUserCode: null,
  },
  {
    id: 59,
    code: "SCERAV30-GBD (new)",
    itemType: "SEMI_PRODUCT",
    name: "CERA (베트남확산) (new)",
    unitText: "EA",
    itemCategoryName: null,
    abstractItemCode: null,
    description: null,
    spec: null,
    texture: null,
    height: null,
    width: null,
    depth: null,
    additional: null,
    usesLotName: true,
    usesLotExpiration: false,
    expirationDayFromProduction: null,
    standardUph: null,
    erpItemSeq: null,
    gradeCode: null,
    createdAt: "2024-12-26T00:43:10.403Z",
    updatedAt: "2024-12-26T00:43:10.403Z",
    creatorUserCode: "test",
    updaterUserCode: null,
  },
  {
    id: 58,
    code: "SCERAV30-HRE (new)",
    itemType: "SEMI_PRODUCT",
    name: "CERA (베트남도포) (new)",
    unitText: "EA",
    itemCategoryName: null,
    abstractItemCode: null,
    description: null,
    spec: null,
    texture: null,
    height: null,
    width: null,
    depth: null,
    additional: null,
    usesLotName: true,
    usesLotExpiration: false,
    expirationDayFromProduction: null,
    standardUph: null,
    erpItemSeq: null,
    gradeCode: null,
    createdAt: "2024-12-26T00:40:56.664Z",
    updatedAt: "2024-12-26T00:40:56.664Z",
    creatorUserCode: "test",
    updaterUserCode: null,
  },
  {
    id: 56,
    code: "SCERAV40-WS3 (new)",
    itemType: "SEMI_PRODUCT",
    name: "CERA (베트남전가공_WS3차) (new)",
    unitText: "EA",
    itemCategoryName: null,
    abstractItemCode: null,
    description: null,
    spec: null,
    texture: null,
    height: null,
    width: null,
    depth: null,
    additional: null,
    usesLotName: true,
    usesLotExpiration: false,
    expirationDayFromProduction: null,
    standardUph: null,
    erpItemSeq: null,
    gradeCode: null,
    createdAt: "2024-12-24T09:42:25.701Z",
    updatedAt: "2024-12-24T09:42:25.701Z",
    creatorUserCode: "test",
    updaterUserCode: null,
  },
  {
    id: 54,
    code: "SCERAV40-WS2 (new)",
    itemType: "SEMI_PRODUCT",
    name: "CERA (베트남전가공_WS2차) (new)",
    unitText: "EA",
    itemCategoryName: null,
    abstractItemCode: null,
    description: null,
    spec: null,
    texture: null,
    height: null,
    width: null,
    depth: null,
    additional: null,
    usesLotName: true,
    usesLotExpiration: false,
    expirationDayFromProduction: null,
    standardUph: null,
    erpItemSeq: null,
    gradeCode: null,
    createdAt: "2024-12-24T09:40:31.801Z",
    updatedAt: "2024-12-24T09:40:31.801Z",
    creatorUserCode: "test",
    updaterUserCode: null,
  },
  {
    id: 53,
    code: "SCERAV40-WS1 (new)",
    itemType: "SEMI_PRODUCT",
    name: "CERA (베트남전가공_WS1차) (new)",
    unitText: "EA",
    itemCategoryName: null,
    abstractItemCode: null,
    description: null,
    spec: null,
    texture: null,
    height: null,
    width: null,
    depth: null,
    additional: null,
    usesLotName: true,
    usesLotExpiration: false,
    expirationDayFromProduction: null,
    standardUph: null,
    erpItemSeq: null,
    gradeCode: null,
    createdAt: "2024-12-24T09:38:21.865Z",
    updatedAt: "2024-12-24T09:38:21.865Z",
    creatorUserCode: "test",
    updaterUserCode: null,
  },
];

export const 재고 = [
  {
    id: "85",
    itemCode: "SCERAV20-SG (new)",
    locationCode: "VN_31",
    lotId: 80,
    closedQuantity: null,
    closedAt: null,
    increaseQuantity: "10",
    decreaseQuantity: "8",
    deltaQuantity: "2",
    quantity: "2",
    updatedAt: "2025-01-02T07:53:36.596Z",
    createdAt: "2025-01-02T07:26:17.845Z",
    lotName: "푸",
    lotExpiration: null,
    lotIncomingAt: null,
    lotCreatedAt: "2025-01-02T07:22:21.053Z",
    itemUsesLotName: true,
    itemUsesLotExpiration: false,
    isUnknown: false,
    betweenIncreaseQuantity: "10",
    betweenDecreaseQuantity: "-8",
    betweenDeltaQuantity: "2",
    quantityAtStart: "0",
    quantityAtEnd: "2",
    locationName: "L_설비12_PPC",
    lot: {
      code: null,
      name: "푸",
      expiration: null,
    },
    unitText: "EA",
    itemName: "CERA (베트남후가공_연마) (new)",
    itemType: "SEMI_PRODUCT",
    spec: null,
  },
  {
    id: "77",
    itemCode: "SCERAV10-PPC (new)",
    locationCode: "VN_1",
    lotId: 82,
    closedQuantity: null,
    closedAt: null,
    increaseQuantity: "12",
    decreaseQuantity: "4",
    deltaQuantity: "8",
    quantity: "8",
    updatedAt: "2025-01-02T07:53:36.632Z",
    createdAt: "2025-01-02T07:25:38.321Z",
    lotName: "250102-37",
    lotExpiration: null,
    lotIncomingAt: null,
    lotCreatedAt: "2025-01-02T07:25:38.316Z",
    itemUsesLotName: true,
    itemUsesLotExpiration: false,
    isUnknown: false,
    betweenIncreaseQuantity: "12",
    betweenDecreaseQuantity: "-4",
    betweenDeltaQuantity: "8",
    quantityAtStart: "0",
    quantityAtEnd: "8",
    lot: {
      code: null,
      name: "250102-37",
      expiration: null,
    },
    locationName: "창고-베트남반제품",
    unitText: "EA",
    itemName: "CERA (베트남표면처리_인산염) (new)",
    itemType: "SEMI_PRODUCT",
    spec: null,
  },
  {
    id: "86",
    itemCode: "SCERAV20-SG (new)",
    locationCode: "VN_31",
    lotId: 81,
    closedQuantity: null,
    closedAt: null,
    increaseQuantity: "4",
    decreaseQuantity: "4",
    deltaQuantity: "0",
    quantity: "0",
    updatedAt: "2025-01-02T07:26:10.930Z",
    createdAt: "2025-01-02T07:25:38.295Z",
    lotName: null,
    lotExpiration: null,
    lotIncomingAt: null,
    lotCreatedAt: "2025-01-02T07:25:38.291Z",
    itemUsesLotName: true,
    itemUsesLotExpiration: false,
    isUnknown: true,
    betweenIncreaseQuantity: "4",
    betweenDecreaseQuantity: "-4",
    betweenDeltaQuantity: "0",
    quantityAtStart: "0",
    quantityAtEnd: "0",
    lot: {
      code: null,
      name: null,
      expiration: null,
    },
    locationName: "L_설비12_PPC",
    unitText: "EA",
    itemName: "CERA (베트남후가공_연마) (new)",
    itemType: "SEMI_PRODUCT",
    spec: null,
  },
  {
    id: "84",
    itemCode: "SCERAV20-SG (new)",
    locationCode: "VN_1",
    lotId: 80,
    closedQuantity: null,
    closedAt: null,
    increaseQuantity: "10",
    decreaseQuantity: "10",
    deltaQuantity: "0",
    quantity: "0",
    updatedAt: "2025-01-02T07:26:17.840Z",
    createdAt: "2025-01-02T07:22:23.633Z",
    lotName: "푸",
    lotExpiration: null,
    lotIncomingAt: null,
    lotCreatedAt: "2025-01-02T07:22:21.053Z",
    itemUsesLotName: true,
    itemUsesLotExpiration: false,
    isUnknown: false,
    betweenIncreaseQuantity: "10",
    betweenDecreaseQuantity: "-10",
    betweenDeltaQuantity: "0",
    quantityAtStart: "0",
    quantityAtEnd: "0",
    lot: {
      code: null,
      name: "푸",
      expiration: null,
    },
    locationName: "창고-베트남반제품",
    unitText: "EA",
    itemName: "CERA (베트남후가공_연마) (new)",
    itemType: "PRODUCT",
    spec: null,
  },
  {
    id: "122",
    itemCode: "SCEROV60-JM (new)",
    locationCode: "VN_1",
    lotId: 79,
    closedQuantity: null,
    closedAt: null,
    increaseQuantity: "1",
    decreaseQuantity: "1",
    deltaQuantity: "0",
    quantity: "0",
    updatedAt: "2025-01-02T02:20:39.411Z",
    createdAt: "2025-01-02T02:20:39.365Z",
    lotName: "250102-36",
    lotExpiration: null,
    lotIncomingAt: null,
    lotCreatedAt: "2025-01-02T02:20:39.362Z",
    itemUsesLotName: true,
    itemUsesLotExpiration: false,
    isUnknown: false,
    betweenIncreaseQuantity: "1",
    betweenDecreaseQuantity: "-1",
    betweenDeltaQuantity: "0",
    quantityAtStart: "0",
    quantityAtEnd: "0",
    lot: {
      code: null,
      name: "250102-36",
      expiration: null,
    },
    locationName: "창고-베트남반제품",
    unitText: "EA",
    itemName: "CERO (베트남분말_JM) (new)",
    itemType: "SEMI_PRODUCT",
    spec: null,
  },
  {
    id: "118",
    itemCode: "SCEROV60-HD (new)",
    locationCode: "VN_22",
    lotId: 78,
    closedQuantity: null,
    closedAt: null,
    increaseQuantity: "0",
    decreaseQuantity: "1",
    deltaQuantity: "-1",
    quantity: "-1",
    updatedAt: "2025-01-02T02:20:39.345Z",
    createdAt: "2025-01-02T02:20:39.345Z",
    lotName: null,
    lotExpiration: null,
    lotIncomingAt: null,
    lotCreatedAt: "2025-01-02T02:20:39.340Z",
    itemUsesLotName: true,
    itemUsesLotExpiration: false,
    isUnknown: true,
    betweenIncreaseQuantity: "0",
    betweenDecreaseQuantity: "-1",
    betweenDeltaQuantity: "-1",
    quantityAtStart: "0",
    quantityAtEnd: "-1",
    lot: {
      code: null,
      name: null,
      expiration: null,
    },
    locationName: "L_설비3_JM",
    unitText: "EA",
    itemName: "CERO (베트남분말_HD) (new)",
    itemType: "SUBSIDIARY",
    spec: null,
  },
  {
    id: "116",
    itemCode: "SCEROV60-HD (new)",
    locationCode: "VN_1",
    lotId: 77,
    closedQuantity: null,
    closedAt: null,
    increaseQuantity: "1",
    decreaseQuantity: "0",
    deltaQuantity: "1",
    quantity: "1",
    updatedAt: "2025-01-02T02:13:42.505Z",
    createdAt: "2025-01-02T02:13:42.505Z",
    lotName: "250102-35",
    lotExpiration: null,
    lotIncomingAt: null,
    lotCreatedAt: "2025-01-02T02:13:42.501Z",
    itemUsesLotName: true,
    itemUsesLotExpiration: false,
    isUnknown: false,
    betweenIncreaseQuantity: "1",
    betweenDecreaseQuantity: "0",
    betweenDeltaQuantity: "1",
    quantityAtStart: "0",
    quantityAtEnd: "1",
    lot: {
      code: null,
      name: "250102-35",
      expiration: null,
    },
    locationName: "창고-베트남반제품",
    unitText: "EA",
    itemName: "CERO (베트남분말_HD) (new)",
    itemType: "MATERIAL",
    spec: null,
  },
  {
    id: "131",
    itemCode: "SCEROV60-SC",
    locationCode: "VN_21",
    lotId: 76,
    closedQuantity: null,
    closedAt: null,
    increaseQuantity: "0",
    decreaseQuantity: "1",
    deltaQuantity: "-1",
    quantity: "-1",
    updatedAt: "2025-01-02T02:13:42.479Z",
    createdAt: "2025-01-02T02:13:42.479Z",
    lotName: null,
    lotExpiration: null,
    lotIncomingAt: null,
    lotCreatedAt: "2025-01-02T02:13:42.472Z",
    itemUsesLotName: true,
    itemUsesLotExpiration: false,
    isUnknown: true,
    betweenIncreaseQuantity: "0",
    betweenDecreaseQuantity: "-1",
    betweenDeltaQuantity: "-1",
    quantityAtStart: "0",
    quantityAtEnd: "-1",
    lot: {
      code: null,
      name: null,
      expiration: null,
    },
    locationName: "L_설비2_HD",
    unitText: "EA",
    itemName: "CERO (베트남분말_SC)",
    itemType: "SEMI_PRODUCT",
    spec: null,
  },
  {
    id: "127",
    itemCode: "SCEROV60-SC",
    locationCode: "VN_20",
    lotId: 75,
    closedQuantity: null,
    closedAt: null,
    increaseQuantity: "1",
    decreaseQuantity: "0",
    deltaQuantity: "1",
    quantity: "1",
    updatedAt: "2025-01-02T01:59:04.517Z",
    createdAt: "2025-01-02T01:59:04.517Z",
    lotName: "item_sc1",
    lotExpiration: null,
    lotIncomingAt: null,
    lotCreatedAt: "2025-01-02T01:59:01.376Z",
    itemUsesLotName: true,
    itemUsesLotExpiration: false,
    isUnknown: false,
    betweenIncreaseQuantity: "1",
    betweenDecreaseQuantity: "0",
    betweenDeltaQuantity: "1",
    quantityAtStart: "0",
    quantityAtEnd: "1",
    lot: {
      code: null,
      name: "item_sc1",
      expiration: null,
    },
    locationName: "L_설비1_SC",
    unitText: "EA",
    itemName: "CERO (베트남분말_SC)",
    itemType: "SEMI_PRODUCT",
    spec: null,
  },
  {
    id: "108",
    itemCode: "SCERAV40-WS3(new)",
    locationCode: "VN_1",
    lotId: 73,
    closedQuantity: null,
    closedAt: null,
    increaseQuantity: "1",
    decreaseQuantity: "0",
    deltaQuantity: "1",
    quantity: "1",
    updatedAt: "2024-12-27T07:50:17.409Z",
    createdAt: "2024-12-27T07:50:17.409Z",
    lotName: "241227-34",
    lotExpiration: null,
    lotIncomingAt: null,
    lotCreatedAt: "2024-12-27T07:48:25.876Z",
    itemUsesLotName: true,
    itemUsesLotExpiration: false,
    isUnknown: false,
    betweenIncreaseQuantity: "0",
    betweenDecreaseQuantity: "0",
    betweenDeltaQuantity: "0",
    quantityAtStart: "1",
    quantityAtEnd: "1",
    lot: {
      code: null,
      name: "241227-34",
      expiration: null,
    },
    locationName: "창고-베트남반제품",
    unitText: "EA",
    itemName: "CERA (베트남전가공_WS3차) (new)",
    itemType: "SEMI_PRODUCT",
    spec: null,
  },
];

export const data = [
  {
    firstName: "John",
    lastName: "Doe",
    valuse: Array.from({ length: 15 }, (_, i) => i).map(() => Math.random() * 100 - 50),
  },
  {
    firstName: "Maria",
    lastName: "Garcia",
    valuse: Array.from({ length: 15 }, (_, i) => i).map(() => Math.random() * 100 - 50),
  },
  {
    firstName: "Nancy",
    lastName: "Jones",
    valuse: Array.from({ length: 15 }, (_, i) => i).map(() => Math.random() * 100 - 50),
  },
  {
    firstName: "James",
    lastName: "Smith",
    valuse: Array.from({ length: 15 }, (_, i) => i).map(() => Math.random() * 100 - 50),
  },
  {
    firstName: "James",
    lastName: "Smith",
    valuse: Array.from({ length: 15 }, (_, i) => i).map(() => Math.random() * 100 - 50),
  },
  {
    firstName: "James",
    lastName: "Smith",
    valuse: Array.from({ length: 15 }, (_, i) => i).map(() => Math.random() * 100 - 50),
  },

  {
    firstName: "James",
    lastName: "Smith",
    valuse: Array.from({ length: 15 }, (_, i) => i).map(() => Math.random() * 100 - 50),
  },
];

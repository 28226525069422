import {
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
  // createRow,
  type MRT_ColumnDef,
} from "mantine-react-table";
import { useMemo, useState } from "react";

import { workLogs } from "@/api/workLogs/useWorksLogsQuery";
import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { LocationDetailForm } from "@/features/location/detail/LocationDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { MantineReactCommonTable } from "@/features/ui/mantineTable";
import { UsersDetailForm } from "@/features/users/components/form/UsersDetailForm";
import { WorkView } from "@/features/work/WorkView/WorkView";
import DatetimeUtil from "@/utils/dateTimeUtil";
import { setWorkStatus, trackingStatusColor } from "@/utils/workStatus";
import { Badge, DefaultMantineColor } from "@mantine/core";
import { WorkLogsGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { ProductionPlanFormProps } from "../../view";

export const WorkRecordsTabs = ({ data }: ProductionPlanFormProps) => {
  const { openModal } = useModal();
  const workId = data?.works && data?.works[0]?.id;

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 1,
    pageSize: 10,
  });

  // 각 컬럼 sort
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  // 각 컬럼 filter
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  // 전체 검색 (단, serchFields에 필드를 직접 입력해줘야함.)
  const [globalFilter, setGlobalFilter] = useState("");

  // sort를 id 별 string으로 만들어줌
  const sortString = sorting.map(({ id, desc }) => (desc ? `-${id}` : id)).join(",");

  // filter를 id 별 like를 사용하여 단어가 포함되어있는지 확인
  const filterQuery: { [key: string]: { $iLike: string } } = columnFilters.reduce<{
    [key: string]: { $iLike: string };
  }>((acc, filter) => {
    acc[filter.id] = { $iLike: `%${filter.value}%` as string };
    return acc;
  }, {});

  const { data: workLogsData } = useQuery({
    ...workLogs.get({
      query: data
        ? {
            $and: [
              { workId: { $eq: workId } },
              ...Object.keys(filterQuery).map((key) => ({ [key]: filterQuery[key] })),
            ],
          }
        : {},
      populate: [
        "itemUnit",
        "defectName",
        "alreadyCanceled",
        "creatorUser",
        "downtimeReasonName",
        "getEquipment",
      ],
      pageSize: pagination.pageSize,
      page: pagination.pageIndex,
      sort: sortString || undefined,
      search: globalFilter,
      searchFields: ["itemCode", "creatorUserCode"],
    }),
    enabled: !!data,
  });

  const rows: WorkLogsGet200ResponseRowsInner[] = workLogsData?.data?.rows ?? [];

  const { t } = useTranslation();

  const columns = useMemo<MRT_ColumnDef<WorkLogsGet200ResponseRowsInner>[]>(
    () => [
      {
        accessorFn: (row) => row.id,
        accessorKey: "logId",
        header: "로그번호",
        enableColumnFilter: false,
        size: 80,
      },
      {
        accessorFn: (row) => row.workId,
        accessorKey: "workId",
        header: "작업지시",
        size: 120,
        enableColumnFilter: false,
        mantineEditTextInputProps: {
          type: "string",
          required: true,
        },
        Cell: (rows) => {
          return (
            <DetailLink
              onClick={() => {
                openModal(<WorkView workId={rows.row._valuesCache.workId} />, null, "");
              }}
              justify="flex-start"
            >
              {rows.row._valuesCache.workId}
            </DetailLink>
          );
        },
      },
      {
        accessorFn: (row) =>
          row.logData?.incoming && row.logData?.incoming.createdAt
            ? DatetimeUtil(row.logData?.incoming.createdAt)
            : row.logData?.outgoing && row.logData?.outgoing.createdAt
              ? DatetimeUtil(row.logData?.outgoing?.createdAt)
              : "",
        accessorKey: "createdAt",
        enableColumnFilter: false,
        header: "일시",
        size: 120,
        mantineEditTextInputProps: {
          type: "string",
          required: true,
        },
      },
      {
        // accessorFn: (row) => t(row.workLogType),
        accessorKey: "workLogType",
        header: "타입",
        size: 120,
        enableColumnFilter: false,
        mantineEditTextInputProps: {
          type: "string",
          required: true,
        },
        Cell: (rows) => {
          const workLogType = rows.row._valuesCache.workLogType as keyof typeof trackingStatusColor;

          return (
            <Badge color={trackingStatusColor[workLogType] as DefaultMantineColor}>
              {setWorkStatus(rows.row._valuesCache.workLogType)}
            </Badge>
          );
        },
      },
      {
        accessorFn: (row) => row.quantity,
        accessorKey: "quantity",
        header: "수량",
        size: 120,
        enableColumnFilter: false,
        mantineEditTextInputProps: {
          type: "string",
          required: true,
        },
        Cell: (rows) => {
          const unitText = rows.row.original.unitText;
          return (
            <div style={{ textAlign: "right" }}>
              {rows.row._valuesCache.quantity !== null ? rows.row._valuesCache.quantity : "0"}{" "}
              {unitText}
            </div>
          );
        },
      },
      {
        accessorFn: (row) =>
          row.logData?.incoming ? (
            <DetailLink
              onClick={() => {
                openModal(
                  <ItemsDetailForm itemCode={row?.logData?.incoming?.itemCode} />,
                  null,
                  "",
                );
              }}
              justify="flex-start"
            >
              {row.logData?.incoming.itemCode}
            </DetailLink>
          ) : (
            <DetailLink
              onClick={() => {
                openModal(
                  <ItemsDetailForm itemCode={row?.logData?.outgoing?.itemCode} />,
                  null,
                  "",
                );
              }}
              justify="flex-start"
            >
              {row?.logData?.outgoing?.itemCode}
            </DetailLink>
          ),
        accessorKey: "itemCode",
        header: "BOM 품목",
        size: 120,
        mantineEditTextInputProps: {
          type: "string",
          required: true,
        },
      },
      {
        accessorFn: (row) =>
          row.logData?.incoming
            ? [
                <div>이름 : {row.logData?.incoming.lotData?.name}</div>,
                <div>유효기한 : {row.logData?.incoming?.lotData?.expiration}</div>,
              ]
            : [
                <div>이름 : {row.logData?.outgoing?.lotData?.name}</div>,
                <div>유효기한 : {row.logData?.outgoing?.lotData?.expiration}</div>,
              ],
        accessorKey: "lotData",
        header: "BOM 로트",
        enableColumnFilter: false,
        size: 160,
        mantineEditTextInputProps: {
          type: "string",
          required: true,
        },
      },
      {
        accessorFn: (row) =>
          row.logData?.incoming ? (
            <DetailLink
              onClick={() => {
                openModal(
                  <LocationDetailForm locationCode={row?.logData?.incoming?.locationCode} />,
                  null,
                  "창고 상세",
                );
              }}
              justify="flex-start"
            >
              {row.logData?.incoming.locationCode}
            </DetailLink>
          ) : (
            <DetailLink
              onClick={() => {
                openModal(
                  <LocationDetailForm locationCode={row?.logData?.outgoing?.locationCode} />,
                  null,
                  "창고 상세",
                );
              }}
              justify="flex-start"
            >
              {row.logData?.outgoing?.locationCode}
            </DetailLink>
          ),
        accessorKey: "locationCode",
        header: "투입 창고",
        enableColumnFilter: false,
        size: 120,
        mantineEditTextInputProps: {
          type: "string",
          required: true,
        },
      },
      {
        accessorFn: (row) => row.parentWorkLogId,
        accessorKey: "parentWorkLogId",
        header: "연관 로그번호",
        enableColumnFilter: false,
        size: 120,
        mantineEditTextInputProps: {
          type: "string",
          required: true,
        },
      },
      {
        accessorFn: (row) => row.creatorUserCode,
        accessorKey: "creatorUserCode",
        header: "작업자",
        size: 120,
        mantineEditTextInputProps: {
          type: "string",
          required: true,
        },
        Cell: (rows) => {
          return (
            <DetailLink
              onClick={() =>
                openModal(
                  <UsersDetailForm UserCode={rows.row._valuesCache.creatorUserCode} />,
                  null,
                  "",
                )
              }
              justify="flex-start"
            >
              {rows.row._valuesCache.creatorUserCode}
            </DetailLink>
          );
        },
      },
    ],
    [],
  );

  return (
    <MantineReactCommonTable
      data={rows ?? []}
      columns={columns}
      pagination={pagination}
      setPagination={setPagination}
      totalCount={workLogsData?.data?.total}
      totalPages={workLogsData?.data?.totalPages}
      sorting={sorting}
      setSorting={setSorting}
      columnFilters={columnFilters}
      setColumnFilters={setColumnFilters}
      globalFilter={globalFilter}
      setGlobalFilter={setGlobalFilter}
      enableTopToolbar={true}
    />
  );
};

import "@glideapps/glide-data-grid/dist/index.css";
import {
  DataEditor,
  DataEditorRef,
  GridCell,
  GridCellKind,
  GridColumn,
  Item,
} from "@glideapps/glide-data-grid";
import React, { useState } from "react";
import { allCells } from "@glideapps/glide-data-grid-cells";
import { 품목, 재고 } from "./data";
import timeUtil from "@/utils/timeUtil";

const columns: GridColumn[] = [
  { title: "품목코드", hasMenu: false, width: 160, icon: "headerBoolean", id: "0" },
  { title: "품목명", id: "1", width: 200 },
  { title: "규격", id: "2" },
  { title: "품목유형", width: 80, id: "3" },
  { title: "로트명", width: 100, id: "4" },
  { title: "로트유효기한", width: 50, id: "5" },
  { title: "위치", width: 100, id: "6" },
  { title: "입고일", width: 100, id: "7" },
  { title: "마감재고", width: 100, id: "8" },
  { title: "마감일시", width: 100, id: "9" },
  { title: "추가된재고", width: 70, id: "10" },
  { title: "감소된재고", width: 70, id: "11" },
  { title: "재고변화량", width: 70, id: "12" },
  { title: "이월재고", width: 70, id: "13" },
  { title: "최종재고", width: 70, id: "14" },
];
export const itemTypeColor: any = {
  MATERIAL: { color: "rgb(62,125,209)", bgcolor: "rgb(234,254,245)", nm: "원자재" },
  PRODUCT: { color: "rgb(93,176,89)", bgcolor: "rgb(238,250,239)", nm: "제품" },
  SUBSIDIARY: { color: "rgb(233,167,57)", bgcolor: "rgb(254,249,222)", nm: "부자재" },
  SEMI_PRODUCT: { color: "rgb(230,112,46)", bgcolor: "rgb(253,244,231)", nm: "반제품" },
} as const;

export const Example = () => {
  const dataEditorRef = React.useRef<DataEditorRef | null>(null);
  const [column, setColumn] = useState<GridColumn[]>(columns);

  function getData([col, row]: Item): GridCell {
    const person = 재고[row];
    // 품목코드;
    if (col === 0) {
      return {
        kind: GridCellKind.Custom,
        allowOverlay: false,
        copyData: person.itemCode,
        data: {
          kind: "button-cell",
          onClick: () => alert(`${person.itemCode} OPEN`),
          // 커스텀
          title: `${person.itemCode}🔗`,
          color: "#1E90FF",
        },
      };
    } else if (col === 1) {
      // 품목명
      return {
        kind: GridCellKind.Custom,
        allowOverlay: false,
        copyData: person.itemName,
        data: {
          kind: "button-cell",
          onClick: () => alert(`${person.itemCode} OPEN`),
          // 커스텀
          title: `${person.itemName}🔗`,

          color: "#1E90FF",
        },
      };
    } else if (col === 2) {
      // 규격
      return {
        kind: GridCellKind.Text,
        data: person.lotExpiration ?? "",
        allowOverlay: false,
        displayData: person.lotExpiration ?? "",
        copyData: person.lotExpiration ?? "",
      };
    } else if (col === 3) {
      // 품목유형
      return {
        kind: GridCellKind.Custom,
        allowOverlay: false,
        copyData: itemTypeColor[person.itemType].nm,
        data: {
          kind: "button-cell",
          // 커스텀
          title: itemTypeColor[person.itemType].nm,
          backgroundColor: itemTypeColor[person.itemType].bgcolor,
          color: itemTypeColor[person.itemType].color,
          borderColor: itemTypeColor[person.itemType].color,
          borderRadius: 12,
        },
      };
    } else if (col === 4) {
      // 로트명
      return {
        kind: GridCellKind.Text,
        data: person.lot.name ?? "",
        allowOverlay: false,
        displayData: person.lot.name ?? "",
        copyData: person.lot.name ?? "",
      };
    } else if (col === 5) {
      // 로트유효기간
      return {
        kind: GridCellKind.Text,
        data: person.lot.expiration ?? "",
        allowOverlay: false,
        displayData: person.lot.expiration ?? "",
        copyData: person.lot.expiration ?? "",
      };
    } else if (col === 6) {
      // 위치
      return {
        kind: GridCellKind.Custom,
        allowOverlay: false,
        copyData: person.locationName,
        data: {
          kind: "button-cell",
          onClick: () => alert(`${person.itemCode} OPEN`),
          // 커스텀
          title: person.locationName,
          color: "#1E90FF",
        },
      };
    } else if (col === 7) {
      // 입고일
      return {
        kind: GridCellKind.Text,
        data: person.lotCreatedAt ?? "",
        allowOverlay: false,
        displayData: person.lotCreatedAt ? timeUtil(person.lotCreatedAt) : "",
        copyData: person.lotCreatedAt ?? "",
      };
    } else if (col === 8) {
      // 마감 재고
      return {
        kind: GridCellKind.Custom,
        allowOverlay: false,
        copyData: "4",
        data: {
          kind: "ㅠㅕ-cell",
          html: "<button>Click me!</button>",
        },
      };
    } else if (col === 9) {
      // 마감일시
      return {
        kind: GridCellKind.Custom,
        allowOverlay: false,
        copyData: "4",
        data: {
          kind: "ㅠㅕ-cell",
          html: "<button>Click me!</button>",
        },
      };
    } else if (col === 10) {
      // 추가된재고
      return {
        kind: GridCellKind.Text,
        data: `${person.betweenIncreaseQuantity}EA`,
        allowOverlay: false,
        displayData: `${person.betweenIncreaseQuantity}EA`,
        copyData: `${person.betweenIncreaseQuantity}EA`,
      };
    } else if (col === 11) {
      return {
        kind: GridCellKind.Text,
        data: `${person.betweenDecreaseQuantity}EA`,
        allowOverlay: false,
        displayData: `${person.betweenDecreaseQuantity}EA`,
        copyData: `${person.betweenDecreaseQuantity}EA`,
      };
    } else if (col === 12) {
      return {
        kind: GridCellKind.Text,
        data: `${person.deltaQuantity}EA`,
        allowOverlay: false,
        displayData: `${person.deltaQuantity}EA`,
        copyData: `${person.deltaQuantity}EA`,
      };
    } else if (col === 13) {
      return {
        kind: GridCellKind.Text,
        data: `${person.quantityAtStart}EA`,
        allowOverlay: false,
        displayData: `${person.quantityAtStart}EA`,
        copyData: `${person.quantityAtStart}EA`,
      };
    } else if (col === 14) {
      return {
        kind: GridCellKind.Custom,
        allowOverlay: false,
        copyData: person.locationName,
        data: {
          kind: "button-cell",
          onClick: () => alert(`${person.quantityAtEnd} OPEN`),
          // 커스텀
          title: `${person.quantityAtEnd}EA🔗`,
          color: "#1E90FF",
        },
      };
    } else {
      throw new Error();
    }
  }

  return (
    <div className="test">
      <div style={{ padding: 20 }}>
        <h3>재고관리 &gt; 재고현황 (glide-data-grid )</h3>
        <DataEditor
          width={"100%"}
          className="grid"
          ref={dataEditorRef}
          columns={column}
          getCellContent={getData}
          rows={품목.length}
          maxColumnAutoWidth={200}
          // onHeaderClicked={onHeaderClicked}
          // onHeaderMenuClick={onHeaderMenuClick}
          // theme={{ bgCell: "rgba(234,255,255,30)" }}
          onPaste={true}
          getCellsForSelection={true}
          customRenderers={allCells}
          // showSearch={true}
          //컬럼 리사이징
          onColumnResize={(row, newsize) => {
            setColumn((prevColumns) => {
              return prevColumns.map((col) => {
                if (col.id === row.id) {
                  return { ...col, width: newsize };
                }
                return col;
              });
            });
          }}
          rowMarkers={"checkbox-visible"}
          rowSelectionMode={"multi"}
          onCellClicked={(e) => console.log("체크된 로우번호", e)}
          // maxColumnWidth={200}
          // minColumnWidth={10}
          // onGridSelectionChange={(d) => console.log("?>?>", d)}
          onCellEdited={([col, row], editedCell) => console.log(col, row, editedCell)}
        />
      </div>
    </div>
  );
};

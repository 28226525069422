import { ScmInstance } from "@/instance/axios";
import { AuthSignupPost201ResponseEquipmentPurchaseOrderItem } from "@sizlcorp/sizl-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import dayjs from "dayjs";

export type PurchaseOrderItemsOption = {
  value: string;
  label: string;
};

const getAutoCompleteKeyword = (keyword: string, query: any) =>
  ScmInstance.purchaseOrderItemsFindPost({
    query: JSON.stringify(query),
    sort: "deliveryDeadline",
  }).then((res: AxiosResponse<AuthSignupPost201ResponseEquipmentPurchaseOrderItem[]>) => res.data);

export const usePurchaseOrderItemsAutoComplete = (
  focus = false,
  query = {},
  keyword = "",
  options?: {
    onSuccess: (data: PurchaseOrderItemsOption[]) => void;
  },
) => {
  return useQuery(
    ["purchaseOrderItems-get-autocomplete", keyword, query],
    () => getAutoCompleteKeyword(keyword, query),
    {
      // enabled: focus,
      select: (data: AuthSignupPost201ResponseEquipmentPurchaseOrderItem[]) =>
        data?.map((purchaseOrderItems) => {
          return {
            value: purchaseOrderItems.id?.toString() as string,
            label: `아이디 : ${purchaseOrderItems.id}, 업체명: ${purchaseOrderItems.partnerCode ?? "-"}, 수량 : ${purchaseOrderItems.quantity}, 접수일 : ${purchaseOrderItems.orderDate ? dayjs(purchaseOrderItems.orderDate).format("YYYY-MM-DD") : "-"}, 납기일 : ${purchaseOrderItems.deliveryDeadline ? dayjs(purchaseOrderItems.deliveryDeadline).format("YYYY-MM-DD") : "-"}`,
            group: purchaseOrderItems.partnerCode ?? "-",
          };
        }) ?? [],
      onSuccess: options?.onSuccess,
    },
  );
};

const getPurchaseOrderItemsByCode = (code: string | null, query: any) =>
  ScmInstance.purchaseOrderItemsFindPost({
    ...(code && { search: code }),
    searchFields: ["itemCode"],
    query: JSON.stringify(query)
  }).then((res: AxiosResponse<AuthSignupPost201ResponseEquipmentPurchaseOrderItem[]>) => res.data);

export const usePurchaseOrderItemsCode = (
  enabled: boolean,
  code: string | null,
  query = {},
  options?: {
    onSuccess: (data?: PurchaseOrderItemsOption[]) => void;
  },
) => {
  return useQuery(
    ["purchaseOrderItems-get-code", code],
    () => getPurchaseOrderItemsByCode(code, query),
    {
      enabled: enabled ?? false,
      select: (data: AuthSignupPost201ResponseEquipmentPurchaseOrderItem[]) =>
        data?.map((purchaseOrderItems) => {
          return {
            value: purchaseOrderItems.id?.toString() as string,
            label: `업체명: ${purchaseOrderItems.partnerCode ?? "-"}, 수량 : ${purchaseOrderItems.quantity}, 접수일 : ${purchaseOrderItems.orderDate ? dayjs(purchaseOrderItems.orderDate).format("YYYY-MM-DD") : "-"}, 납기일 : ${purchaseOrderItems.deliveryDeadline ? dayjs(purchaseOrderItems.deliveryDeadline).format("YYYY-MM-DD") : "-"}`,
            group: purchaseOrderItems.partnerCode ?? "-"
          };
        }) ?? [],
      onSuccess: options?.onSuccess,
    }
  );
};

import { mutatePurchaseOrders } from "@/api/purchaseOrder/usePurchaseOrder";
import { useModal } from "@/features/modal/ModalStackManager";
import { PurchaseOrderItemsCreateForm } from "@/features/purchaseOrderItems/form/createForm";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormSelect } from "@/features/standard/Form/FormSelect";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import customAlert from "@/features/ui/alert/alert";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { PartnerAutoComplete } from "@/features/ui/autoComplete/partner/partner-autoComplete";
import { UserAutoComplete } from "@/features/ui/autoComplete/user/user-autoComplete";
import { ConfirmForm } from "@/features/ui/confirm";
import { getUserCodeByUser } from "@/utils/checkData";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { Button, Flex, Text, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { ScmApiPurchaseOrdersPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { IconCalendar } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const Create = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  Select: FormSelect,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
  Text: Text,
  Flex: Flex,
  DateInput: DateInput,
});

interface PurchaseOrderCreateFormProps {
  code?: string;
  autoCompleteOnSubmit?: (values: any) => void;
}

export const PurchaseOrderCreateForm = (params: PurchaseOrderCreateFormProps) => {
  const { code, autoCompleteOnSubmit } = params;
  const { openModal, closeModal } = useModal();
  const userCode = getUserCodeByUser();
  const queryClient = useQueryClient();
  const { mutate: createMutate } = useMutation(
    async (params: ScmApiPurchaseOrdersPostRequest) => {
      // mutationFn을 비동기 함수로 정의
      return await mutatePurchaseOrders
        .create(params)
        .mutationFn(params as ScmApiPurchaseOrdersPostRequest | any);
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["purchaseOrders"]); // 성공 시 'purchaseOrders' 캐시 무효화
        closeModal(form.values);
        customAlert(
          `${userCode === "PB" ? "발주 등록에 성공하였습니다." : "수주 등록에 성공하였습니다."}`,
          "등록 성공",
          "green",
        );

        openModal(
          <ConfirmForm
            message={`${userCode === "PB" ? "발주 항목 등록을 하시겠습니까?" : "수주 항목 등록을 하시겠습니까?"}`}
            yesCallback={() => {
              openModal(
                <PurchaseOrderItemsCreateForm purchaseOrder={res.data} />,
                null,
                `${userCode === "PB" ? "발주 항목 추가" : "수주 항목 추가"}`,
                true,
              ).then(() => closeModal(null));
            }}
            noCallback={() => closeModal(null)}
          />,
          null,
          `${userCode === "PB" ? "발주 항목 추가" : "수주 항목 추가"}`,
          true,
        );
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({ error, errorContext: `${userCode === "PB" ? "발주" : "수주"}` }),
          `${userCode === "PB" ? "발주 항목 실패" : "수주 항목 실패"}`,
          "red",
        );
      },
    },
  );
  const form = useForm({
    initialValues: {
      partnerCode: undefined,
      // status : undefined,
      code: code ?? undefined,
      orderDate: undefined,
      deliveryDeadline: undefined,
      userCode: undefined,
      sourceLocationCode: undefined,
    },
  });

  const onSubmit = async () => {
    try {
      createMutate({
        purchaseOrdersGetRequest: {
          partnerCode: form.values.partnerCode!,
          direction: "INBOUND",
          code: code ?? form.values.code!,
          // status : form.values.status!,
          orderDate: form.values.orderDate!,
          deliveryDeadline: form.values.deliveryDeadline!,
          userCode: form.values.userCode!,
          sourceLocationCode: form.values.sourceLocationCode!,
        },
      });
    } catch (error) {
      customAlert(
        handleErrorResponse({ error, errorContext: `${userCode === "PB" ? "발주" : "수주"}` }),
        `${userCode === "PB" ? "발주 항목 실패" : "수주 항목 실패"}`,
        "red",
      );
    }
  };

  return (
    <>
      <Create.Wrapper>
        <PartnerAutoComplete
          label="협력사"
          placeholder="협력사"
          {...form.getInputProps("partnerCode")}
          withAsterisk
        />
        <Create.Input
          withAsterisk
          label={`${userCode === "PB" ? "발주 코드" : "수주 코드"}`}
          placeholder={`${userCode === "PB" ? "발주 코드 입력" : "수주 코드 입력"}`}
          {...form.getInputProps("code")}
        />
        <UserAutoComplete
          label="담당자"
          placeholder="담당자 입력"
          {...form.getInputProps("userCode")}
        />
        <LocationAutoComplete
          label="출고 창고"
          placeholder="출고 창고"
          {...form.getInputProps("sourceLocationCode")}
        />
        <Create.DateInput
          popoverProps={{ withinPortal: true }}
          icon={<IconCalendar size="1.2rem" />}
          label="수주일"
          valueFormat="YYYY-MM-DD"
          {...form.getInputProps("orderDate")}
        />
        <Create.DateInput
          popoverProps={{ withinPortal: true }}
          icon={<IconCalendar size="1.2rem" />}
          label="납기일"
          valueFormat="YYYY-MM-DD"
          {...form.getInputProps("deliveryDeadline")}
        />
        <Create.ButtonBox>
          <Create.Button color="gray" onClick={closeModal}>
            취소
          </Create.Button>
          <Create.Button
            disabled={validateFormValues({
              pageType: "purchaseOrder",
              values: form.values,
            })}
            onClick={autoCompleteOnSubmit ? () => autoCompleteOnSubmit(form.values) : onSubmit}
          >
            {userCode === "PB" ? "발주 생성" : "수주 생성"}
          </Create.Button>
        </Create.ButtonBox>
      </Create.Wrapper>
    </>
  );
};

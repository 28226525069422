import { mutateStockPlans } from "@/api/stockPlan/useStockPlanQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormSelect } from "@/features/standard/Form/FormSelect";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import customAlert from "@/features/ui/alert/alert";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { LotSelectComplete } from "@/features/ui/autoComplete/lot/lot-\bselectComplete";
import { PartnerAutoComplete } from "@/features/ui/autoComplete/partner/partner-autoComplete";
import { UserAutoComplete } from "@/features/ui/autoComplete/user/user-autoComplete";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { Button, Flex, Text, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import {
    StockPlansGet200ResponseRowsInner,
    WmsApiStockPlansStockPlanIdPutRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconCalendar } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

const Update = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  Select: FormSelect,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
  Text: Text,
  Flex: Flex,
  DateInput: DateInput,
});

interface StockPlanUpdateFormProps {
  formatterProps: StockPlansGet200ResponseRowsInner;
}

export const StockPlanUpdateForm = (params: StockPlanUpdateFormProps) => {
  const { formatterProps } = params;
  const { closeModal } = useModal();
  const [itemCode, setItemCode] = useState<string | undefined>(undefined);
  const queryClient = useQueryClient();
  const { mutate: updateMutate } = useMutation(
    (params: WmsApiStockPlansStockPlanIdPutRequest) =>
      mutateStockPlans
        .update(params)
        .mutationFn(params as WmsApiStockPlansStockPlanIdPutRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["stockPlans"]);
        closeModal(form.values);
        customAlert("재고 계획 수정에 성공하였습니다.", "등록 성공", "green");
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({ error, errorContext: "재고 계획" }),
          "재고 계획 수정 실패",
          "red",
        );
      },
    },
  );

  const form = useForm({
    initialValues: {
      partnerCode: formatterProps.partnerCode,
      scheduledAt: formatterProps.scheduledAt ? new Date(formatterProps.scheduledAt) : undefined,
      itemCode: formatterProps.itemCode,
      lotId: formatterProps.lotId,
      quantity: formatterProps.quantity,
      userCode: formatterProps.userCode,
      sourceLocationCode: formatterProps.sourceLocationCode,
      targetLocationCode: formatterProps.targetLocationCode,
      transportationId: formatterProps.transportationId,
    },
  });

  const onSubmit = async () => {
    try {
      updateMutate({
        stockPlanId: formatterProps.id!,
        stockPlansGetRequest: {
          scheduledAt: form.values.scheduledAt?.toDateString()!,
          itemCode: form.values.itemCode!,
          direction: formatterProps.direction!,
          partnerCode: form.values.partnerCode!,
          lotId: form.values.lotId!,
          quantity: form.values.quantity!,
          userCode: form.values.userCode!,
          sourceLocationCode: form.values.sourceLocationCode!,
          targetLocationCode: form.values.targetLocationCode!,
          transportationId: form.values.transportationId!,
        },
      });
    } catch (error) {
      customAlert(
        handleErrorResponse({ error, errorContext: "재고 계획" }),
        "재고 계획 수정 실패",
        "red",
      );
    }
  };

  return (
    <>
      <Update.Wrapper>
        <PartnerAutoComplete
          label="협력사"
          placeholder="협력사"
          {...form.getInputProps("partnerCode")}
          defaultValue={formatterProps.partnerCode}
          error={form.isDirty("partnerCode") ? "저장 필요" : ""}
          withAsterisk
        />
        <Update.DateInput
          popoverProps={{ withinPortal: true }}
          icon={<IconCalendar size="1.2rem" />}
          label="예정일자"
          withAsterisk
          valueFormat="YYYY-MM-DD"
          {...form.getInputProps("scheduledAt")}
          error={form.isDirty("scheduledAt") ? "저장 필요" : ""}
          styles={{
            input: {
              color: form.isDirty("scheduledAt") ? "red !important" : "",
              borderColor: form.isDirty("scheduledAt") ? "red !important" : "",
            },
            error: { fontSize: 11, color: "red" },
          }}
        />
        <ItemAutoComplete
          label="품목"
          placeholder="품목 입력"
          withAsterisk
          {...form.getInputProps("itemCode")}
          defaultValue={formatterProps.itemCode}
          error={form.isDirty("itemCode") ? "저장 필요" : ""}
        />
        <LotSelectComplete
          label="로트"
          placeholder="로트 입력"
          withAsterisk
          itemCodeValue={form.values.itemCode ?? ""}
          {...form.getInputProps("lotId")}
          error={form.isDirty("lotId") ? "저장 필요" : ""}
          defaultValue={formatterProps.lotId?.toString()}
        />
        <Update.Input
          label="수량"
          placeholder="수량 입력"
          {...form.getInputProps("quantity")}
          defaultValue={formatterProps.quantity}
          error={form.isDirty("quantity") ? "저장 필요" : ""}
          styles={{
            input: {
              color: form.isDirty("quantity") ? "red !important" : "",
              borderColor: form.isDirty("quantity") ? "red !important" : "",
            },
            error: { fontSize: 11, color: "red" },
          }}
        />
        <UserAutoComplete
          label="담당자"
          placeholder="담당자 입력"
          {...form.getInputProps("userCode")}
          defaultValue={formatterProps.userCode}
          error={form.isDirty("userCode") ? "저장 필요" : ""}
        />
        <LocationAutoComplete
          label="출고 창고"
          placeholder="출고 창고"
          {...form.getInputProps("sourceLocationCode")}
          defaultValue={formatterProps.sourceLocationCode}
          error={form.isDirty("sourceLocationCode") ? "저장 필요" : ""}
        />
        <LocationAutoComplete
          label="입고 창고"
          placeholder="입고 창고"
          {...form.getInputProps("targetLocationCode")}
          defaultValue={formatterProps.targetLocationCode}
          error={form.isDirty("targetLocationCode") ? "저장 필요" : ""}
        />
        <Update.ButtonBox>
          <Update.Button color="gray" onClick={closeModal}>
            취소
          </Update.Button>
          <Update.Button
            disabled={validateFormValues({
              pageType: "stockPlan",
              values: form.values,
            })}
            onClick={onSubmit}
          >
            재고계획 수정
          </Update.Button>
        </Update.ButtonBox>
      </Update.Wrapper>
    </>
  );
};

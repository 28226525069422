// @fileoverview : 영업관리 > 납품현황
import { DeliveryStatusTable } from "@/features/deliveryStatus/table";
import { ActionButtonBox } from "@/features/standard/ActionButtonBox";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { Header } from "@/features/standard/Header";
import { Main } from "@/features/standard/Main";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import { Button } from "@mantine/core";
import { useTranslation } from "react-i18next";

const DeliveryStatus = Object.assign({}, Main, {
  Header: Header,
  HeaderTitle: HeaderTitle,
  HeaderSubTitle: HeaderSubTitle,
  Wrapper: Wrapper,
  ActionHeader: ActionHeader,
  ActionButton: Button,
  ActionButtonBox: ActionButtonBox,
  DeliveryStatusTable: DeliveryStatusTable,
});

export const DeliveryStatusPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <DeliveryStatus.Wrapper>
        <DeliveryStatus.Header>
          <DeliveryStatus.HeaderTitle>
            {t("common.list", { item: `${t("delivery.deliveryStatus")}` })}
          </DeliveryStatus.HeaderTitle>
          <DeliveryStatus.HeaderSubTitle>
            {t("common.subTitle", { item: `${t("delivery.deliveryStatus")}` })}
          </DeliveryStatus.HeaderSubTitle>
        </DeliveryStatus.Header>
        <DeliveryStatus.DeliveryStatusTable />
      </DeliveryStatus.Wrapper>
    </>
  );
};

import useCancelInputPutMutation from "@/api/input/useCancelInputPutMutation";
import { workLogs } from "@/api/workLogs/useWorksLogsQuery";
import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { LocationDetailForm } from "@/features/location/detail/LocationDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { UsersDetailForm } from "@/features/users/components/form/UsersDetailForm";
import { theme } from "@/styles/theme";
import { setDateFormatStringWithTime } from "@/utils/dateTimeUtil";
import { setToLocaleString } from "@/utils/unitMark";
import { setWorkStatus } from "@/utils/workStatus";
import styled from "@emotion/styled";
import { Badge, Button, Flex, Pagination, Table, Text } from "@mantine/core";
import {
  WorkLogsGet200ResponseRowsInner,
  WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { WorkViewProps } from "./WorkView";

export const WorkProductionHistory = ({ workId }: WorkViewProps) => {
  const [activePage, setActivePage] = useState<number>(1);

  // workId?: number
  const { mutate: cancelInputMutate } = useCancelInputPutMutation("cancel");

  const { data: workLogsData } = useQuery(
    workLogs.get({
      query: {
        $and: [
          { workId: { $eq: workId ?? 0 } },
          {
            $and: [
              {
                workLogType: {
                  $ne: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.TIME_TRACKING_START,
                },
              },
              {
                workLogType: {
                  $ne: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.TIME_TRACKING_END,
                },
              },
              {
                workLogType: {
                  $ne: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.TIME_TRACKING_PAUSE,
                },
              },
              {
                workLogType: {
                  $ne: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.TIME_TRACKING_RESUME,
                },
              },
            ],
          },
        ],
      },
      sort: "-id",
      page: activePage,
      populate: [
        "itemUnit",
        "defectName",
        "alreadyCanceled",
        "creatorUser",
        "downtimeReasonName",
        "getEquipment",
      ],
    }),
  );

  const rows: readonly WorkLogsGet200ResponseRowsInner[] = workLogsData?.data.rows ?? [];

  const workLogTypeColor = {
    INPUT: "blue.7",
    CONSUME: "pink.7",
    CONSUME_IDENTIFIED: "royalblue.7",
    DEFECT: "red.7",
    REWORK: "yellow.7",
    START: "green.7",
    END: "purple.7",
    LOSS: "lime.7",
    PRODUCTION: "gray.7",
    CANCEL_INPUT: "red.4",
    CANCEL_CONSUME_IDENTIFIED: "royalblue.4",
  };

  const onCancelInput = ({ inputLotId }: { inputLotId: number }) => {
    window.confirm("투입을 취소하시겠습니까?") &&
      cancelInputMutate(
        {
          workLogId: inputLotId,
        },
        {
          onError: (e: any) => {
            if (e.response?.data?.message) {
              alert(e.response?.data?.message);
            }
          },
        },
      );
  };

  const { openModal } = useModal();

  return (
    <WorkTabBodyContainer>
      <TabBodyTitle>
        <Text fz="xl" fw={500}>
          작업 히스토리
        </Text>
        <Text fz="xs" fw={400}>
          품목을 생산하기 위한 라우팅과 라우팅에 필요한 BOM들을 보여줍니다.
        </Text>
      </TabBodyTitle>
      <TabBodyTable>
        <Thead>
          <Tr>
            <Th width={80}>로그번호</Th>
            <Th width={120}>일시</Th>
            <Th width={90}>타입</Th>
            <Th width={110}>수량</Th>
            <Th width={200}>BOM 품목</Th>
            <Th width={180}>BOM 로트</Th>
            <Th width={100}>투입 창고</Th>
            <Th width={100}>연관 로그번호</Th>
            <Th>작업자</Th>
            <Th>액션</Th>
          </Tr>
        </Thead>
        <Tbody>
          {rows.length ? (
            rows.map((workLog) => (
              <Tr>
                <Td width={80} style={{ justifyContent: "flex-end" }}>
                  {workLog.id}
                </Td>
                <Td width={120}>{setDateFormatStringWithTime(workLog.createdAt)}</Td>
                <Td width={90}>
                  <Badge
                    color={workLogTypeColor[workLog.workLogType as keyof typeof workLogTypeColor]}
                  >
                    {setWorkStatus(workLog.workLogType)}
                  </Badge>
                </Td>
                <Td width={110} style={{ justifyContent: "flex-end" }}>
                  {setToLocaleString(workLog.quantity)} {workLog?.unitText}
                </Td>
                <Td width={200}>
                  <DetailLink
                    onClick={() =>
                      openModal(
                        <ItemsDetailForm
                          itemCode={
                            workLog.logData?.incoming?.itemCode ??
                            workLog.logData?.outgoing?.itemCode
                          }
                        />,
                        null,
                        "",
                      )
                    }
                  >
                    {workLog.logData
                      ? workLog.logData?.incoming
                        ? workLog.logData?.incoming?.itemData?.name
                        : workLog.logData?.outgoing?.itemData?.name
                      : ""}
                  </DetailLink>
                </Td>
                <Td width={180}>
                  {workLog.logData ? (
                    workLog.logData?.incoming ? (
                      <Flex direction="column">
                        <Text>id: {workLog.logData?.incoming?.lotData?.id}</Text>
                        <Text>이름 : {workLog.logData?.incoming?.lotData?.name}</Text>
                        <Text>유효기한 : {workLog.logData?.incoming?.lotData?.expiration}</Text>
                      </Flex>
                    ) : (
                      <Flex direction="column">
                        <Text>id: {workLog.logData?.outgoing?.lotData?.id}</Text>
                        <Text>이름 : {workLog.logData?.outgoing?.lotData?.name}</Text>
                        <Text>유효기한 : {workLog.logData?.outgoing?.lotData?.expiration}</Text>
                      </Flex>
                    )
                  ) : null}
                </Td>
                <Td width={80}>
                  <DetailLink
                    onClick={() =>
                      openModal(
                        <LocationDetailForm
                          locationCode={
                            workLog.logData?.incoming?.locationCode ??
                            workLog.logData?.outgoing?.locationCode
                          }
                        />,
                        null,
                        "",
                      )
                    }
                  >
                    {workLog.logData
                      ? workLog.logData?.incoming
                        ? workLog.logData?.incoming.locationData?.name
                        : workLog.logData?.outgoing?.locationData?.name
                      : ""}
                  </DetailLink>
                </Td>
                <Td width={120} style={{ justifyContent: "flex-end" }}>
                  {workLog.parentWorkLogId}
                </Td>
                <Td>
                  <DetailLink
                    onClick={() =>
                      openModal(<UsersDetailForm UserCode={workLog.creatorUserCode} />, null, "")
                    }
                  >
                    {workLog.creatorUserName}
                  </DetailLink>
                </Td>
                <Td style={{ justifyContent: "center", alignItems: "center" }}>
                  {workLog.workLogType === WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.INPUT && (
                    <Button
                      disabled={workLog.alreadyCanceled}
                      styles={{
                        root: {
                          "&:hover": {
                            backgroundColor: theme.colors?.yellow?.[5],
                          },
                        },
                      }}
                      onClick={() => onCancelInput({ inputLotId: workLog.id as number })}
                      size="xs"
                      bg={theme.colors?.yellow?.[6]}
                    >
                      {workLog.alreadyCanceled ? "취소됨" : "취소하기"}
                    </Button>
                  )}
                </Td>
              </Tr>
            ))
          ) : (
            <NoDataTr>
              <Text>작업 히스토리가 존재하지 않습니다.</Text>
            </NoDataTr>
          )}
        </Tbody>
      </TabBodyTable>
      <PaginationWrapper>
        <Pagination
          onChange={setActivePage}
          value={activePage}
          total={workLogsData?.data?.totalPages ?? 0}
          size="lg"
          radius="sm"
        />
      </PaginationWrapper>
    </WorkTabBodyContainer>
  );
};

const WorkTabBodyContainer = styled.div`
  display: flex;
  padding: 16px 32px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  gap: 0.5rem;
`;

const TabBodyTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const TabBodyTable = styled(Table)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const Thead = styled.thead`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

const Tr = styled.tr`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
`;

const Th = styled.th<{ width?: number }>`
  display: flex;
  width: ${(props) => (props.width ? `${props.width}px` : `auto`)};
  flex: ${(props) => (props.width ? `0 0 auto` : `1 0 0`)};
  padding: 7px 10px;
  align-items: flex-start;
  gap: 10px;
`;

const Tbody = styled.tbody`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

const Td = styled.td<{ width?: number }>`
  display: flex;
  width: ${(props) => (props.width ? `${props.width}px` : `auto`)};
  flex: ${(props) => (props.width ? `0 0 auto` : `1 0 0`)};
  padding: 7px 10px;
  align-items: flex-start;
  gap: 10px;
  word-break: break-word;
`;

const Content = styled.div`
  display: flex;
  padding: 2px 0px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
`;

const Divider = styled.div`
  height: 1px;
  align-self: stretch;
  background: ${theme.colors?.gray?.[3]};
`;

// const LeftSpace = styled.span`
//   width: 32px;
//   align-self: stretch;
//   background: ${theme.colors?.gray?.[3]};
//   border-bottom: ${theme.colors?.gray?.[3]} solid 1px;
//   opacity: 0.2;
// `;

// const RightSpace = styled.span`
//   flex: 1 0 0;
//   align-self: stretch;
//   background: ${theme.colors?.gray?.[3]};
//   border-bottom: ${theme.colors?.gray?.[3]} solid 1px;
//   opacity: 0.2;
// `;

const PaginationWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const NoDataTr = styled.tr`
  display: flex;
  width: 100%;
  height: 4rem;
  justify-content: center;
  align-items: center;
`;

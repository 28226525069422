// @fileoverview : 영업관리 > 발주현황

import { mutatePurchaseOrders } from "@/api/purchaseOrder/usePurchaseOrder";
import { customFunctions } from "@/config/customFunction";
import { LocationDetailForm } from "@/features/location/detail/LocationDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { OrderCreateForm } from "@/features/order/form/createForm";
import { OrderDetailForm } from "@/features/order/form/detailForm";
import { OrderUpdateForm } from "@/features/order/form/updateForm";
import { OrderActionHeader } from "@/features/order/header/orderActionHeader";
import { OrderTable } from "@/features/order/table";
import { PartnerDetailForm } from "@/features/partner/form/detailForm";
import { ActionButtonBox } from "@/features/standard/ActionButtonBox";
import { Header } from "@/features/standard/Header";
import { Main } from "@/features/standard/Main";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import { useStandardLayout } from "@/features/standardLayout/Context";
import { Filter } from "@/features/standardLayout/Filter";
import { LinkAnchor } from "@/features/standardLayout/LinkAnchor";
import { ListTable } from "@/features/standardLayout/ListTable";
import { PaginationBox } from "@/features/standardLayout/PaginationBox";
import { SearchBox } from "@/features/standardLayout/SearchBox";
import { Sorter } from "@/features/standardLayout/Sorter";
import { ActionHeader } from "@/features/standardLayout/StandardInfoActionHeader";
import {
  flexGrowable,
  flexStable,
  StandardLayoutTableWrapper,
} from "@/features/standardLayout/Styles";
import customAlert from "@/features/ui/alert/alert";
import { ConfirmForm } from "@/features/ui/confirm";
import { UsersDetailForm } from "@/features/users/components/form/UsersDetailForm";
import { useLoader } from "@/hooks/useLoader";
import { getUserCodeByUser } from "@/utils/checkData";
import DatetimeUtil from "@/utils/dateTimeUtil";
import { handleErrorResponse } from "@/utils/errorMessage";
import { purchaseOrderStatusColor, setPurchaseOrderStatus } from "@/utils/scmDataStatus";
import timeUtil from "@/utils/timeUtil";
import { ActionIcon, Avatar, Badge, Button, Flex, Text, Tooltip } from "@mantine/core";
import {
  PurchaseOrdersGet200Response,
  PurchaseOrdersGet200ResponseRowsInner,
  ScmApiPurchaseOrdersPurchaseOrderIdDeleteRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconEdit, IconFilterPlus, IconTrash } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { Column, SelectColumn } from "react-data-grid";
import { useTranslation } from "react-i18next";

const Order = Object.assign({}, Main, {
  Header: Header,
  HeaderTitle: HeaderTitle,
  HeaderSubTitle: HeaderSubTitle,
  Wrapper: Wrapper,
  ActionHeader: ActionHeader,
  ActionButton: Button,
  ActionButtonBox: ActionButtonBox,
  OrderTable: OrderTable,
  OrderCreateForm: OrderCreateForm,
});

export const OrderPage = () => {
  const { openModal, closeModal } = useModal();
  const userCode = getUserCodeByUser();
  const { selectedRows, refetch } = useStandardLayout();
  const { t } = useTranslation();

  const { loading, LoadingOverlay, setLoading } = useLoader();
  const { mutate: deleteMutate } = useMutation(
    (params: ScmApiPurchaseOrdersPurchaseOrderIdDeleteRequest) =>
      mutatePurchaseOrders
        .delete(params)
        .mutationFn(params as any | ScmApiPurchaseOrdersPurchaseOrderIdDeleteRequest),
    {
      onSuccess: () => {
        refetch();
        customAlert(
          `${userCode === "FLOUR" ? `${t("message.deleteSuccess", { item: `${t("purchaseOrder.purchaseOrder")}` })}` : `${t("message.deleteSuccess", { item: `${t("order.order")}` })}`}`,
          t("common.deleteSuccess"),
          "green",
        );
        closeModal(null);
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({
            error,
            errorContext: `${userCode === "FLOUR" ? t("purchaseOrder.code") : t("order.code")}`,
          }),
          `${
            userCode === "FLOUR"
              ? `${t("message.deleteFail", { item: `${t("purchaseOrder.purchaseOrder")}` })}`
              : `${t("message.deleteFail", { item: `${t("order.order")}` })}`
          }`,
          "red",
        );
      },
    },
  );

  const columns: readonly Column<PurchaseOrdersGet200ResponseRowsInner>[] = [
    {
      ...SelectColumn,
      width: 70,
      maxWidth: 500,
      resizable: true,
    },
    {
      key: "id",
      name: `${userCode === "FLOUR" ? t("purchaseOrder.id") : t("order.id")}`,
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <Flex h="100%" justify="flex-end" align="center">
            <LinkAnchor
              onClick={() =>
                openModal(<OrderDetailForm purchaseOrderId={formatterProps.row.id} />, null, "")
              }
            >
              {formatterProps.row.id}
            </LinkAnchor>
          </Flex>
        );
      },
    },
    {
      key: "code",
      name: `${userCode === "FLOUR" ? t("purchaseOrder.code") : t("order.code")}`,
      sortable: true,
      resizable: true,
    },
    {
      key: "status",
      name: `${userCode === "FLOUR" ? t("purchaseOrder.status") : t("order.status")}`,
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <Badge
            variant="filled"
            color={
              purchaseOrderStatusColor[
                formatterProps.row.status as keyof typeof purchaseOrderStatusColor
              ]
            }
          >
            {setPurchaseOrderStatus(formatterProps.row.status ?? "")}
          </Badge>
        );
      },
    },
    {
      key: "partner.name",
      name: t("partner.name"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <LinkAnchor
            onClick={() =>
              openModal(
                <PartnerDetailForm partnerCode={formatterProps.row.partnerCode} />,
                null,
                `${t("common.detail", { item: `${t("partner.partner")}` })}`,
              )
            }
          >
            {formatterProps.row.partner?.name}
          </LinkAnchor>
        );
      },
    },
    {
      key: "manager",
      name: t("order.manager"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <LinkAnchor
            onClick={() =>
              openModal(
                <UsersDetailForm UserCode={formatterProps.row?.userCode} />,
                null,
                `${t("common.detail", { item: `${t("user.user")}` })}`,
              )
            }
          >
            {formatterProps.row.manager}
          </LinkAnchor>
        );
      },
    },
    {
      key: "numberOfPurchaseOrderItems",
      name: `${userCode === "FLOUR" ? t("purchaseOrder.numberOfOrderItems") : t("order.numberOfOrderItems")}`,
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        const betweenDecreaseQuantity = row?.numberOfPurchaseOrderItems ?? 0;
        return <Flex justify="flex-end">{betweenDecreaseQuantity}</Flex>;
      },
    },
    {
      key: "targetLocationName",
      name: t("order.toLocationName"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <LinkAnchor
            onClick={() =>
              openModal(
                <LocationDetailForm locationCode={formatterProps?.row?.targetLocationCode} />,
                null,
                `${t("common.detail", { item: `${t("location.location")}` })}`,
              )
            }
          >
            {formatterProps.row.targetLocationName}
          </LinkAnchor>
        );
      },
    },
    {
      key: "orderDate",
      name: t("order.orderDate"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <div>
            {formatterProps.row.orderDate !== null
              ? timeUtil(formatterProps.row.orderDate as string)
              : ""}
          </div>
        );
      },
    },
    {
      key: "createdAt",
      name: t("order.createdAt"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <div>
            {formatterProps.row.createdAt !== null
              ? DatetimeUtil(formatterProps.row.createdAt as string)
              : ""}
          </div>
        );
      },
    },
    {
      key: "deliveryDeadline",
      name: t("order.deliveryDate"),
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <div>
            {formatterProps.row.deliveryDeadline !== null
              ? timeUtil(formatterProps.row.deliveryDeadline as string)
              : ""}
          </div>
        );
      },
    },
    ...(!customFunctions.ADD_ORDER_ACTION_BUTTON
      ? [
          {
            key: "options",
            name: t("common.action"),
            sortable: true,
            resizable: true,
            formatter: (formatterProps: {
              row: PurchaseOrdersGet200ResponseRowsInner | undefined;
            }) => {
              return (
                <Flex w="100%" h="100%" justify="center" align="center" gap="md">
                  <ActionIcon
                    variant="subtle"
                    onClick={() => {
                      openModal(
                        <OrderUpdateForm formatterProps={formatterProps.row} />,
                        null,
                        `${userCode === "FLOUR" ? t("common.edit", { item: `${t("purchaseOrder.purchaseOrder")}` }) : t("common.edit", { item: `${t("order.order")}` })}`,
                      );
                    }}
                    color="yellow"
                  >
                    <IconEdit />
                  </ActionIcon>
                  <ActionIcon
                    variant="subtle"
                    onClick={() => {
                      openModal(
                        <ConfirmForm
                          message={t("message.rowDelete")}
                          yesCallback={() => {
                            deleteMutate({ purchaseOrderId: formatterProps?.row?.id as number });
                          }}
                          noCallback={() => {
                            closeModal(null);
                          }}
                        />,
                        null,
                        `${userCode === "FLOUR" ? t("common.delete", { item: `${t("purchaseOrder.purchaseOrder")}` }) : t("common.delete", { item: `${t("order.order")}` })}`,
                      );
                    }}
                    color="red"
                  >
                    <IconTrash />
                  </ActionIcon>
                </Flex>
              );
            },
          },
        ]
      : []),
  ];

  const handleDelete = () => {
    if ([...selectedRows].length === 0) {
      customAlert(
        `${t("message.deleteSelect", { item: `${t("order.order")}` })}`,
        `${t("common.deleteFail", { item: `${t("order.order")}` })}`,
        "red",
      );
      return;
    }

    openModal(
      <ConfirmForm
        message={t("message.rowDelete")}
        yesCallback={() => {
          [...selectedRows].forEach((row) => {
            deleteMutate({ purchaseOrderId: row });
          });

          selectedRows.clear();
        }}
        noCallback={() => {}}
        commonCallback={() => closeModal(null)}
      />,
      null,
      `${t("common.delete", { item: `${t("order.order")}` })}`,
    );
  };

  return (
    <Flex w="100%" h="100%" gap="xs" direction="column">
      <LoadingOverlay />
      <OrderActionHeader setLoading={setLoading} />
      <Flex bg="white" px="sm" direction="column" gap="xs">
        <Text fz="xl" fw={600}>
          {userCode === "FLOUR" ? t("purchaseOrder.purchaseOrder") : t("order.order")}
        </Text>
        <Text fz="xs">{t("order.description")}</Text>
      </Flex>
      <Flex justify="space-between" align="flex-start" px="sm">
        <Flex wrap="wrap" styles={flexGrowable}>
          <Flex gap="xs" align="center" h="100%" wrap="wrap" direction="row">
            <Tooltip label={t("common.filterDescription")}>
              <Avatar color="blue" variant="outline">
                <IconFilterPlus />
              </Avatar>
            </Tooltip>
            <Flex direction="column" gap="xs" wrap="wrap">
              <Filter />
              <Sorter />
            </Flex>
          </Flex>
        </Flex>
        <Flex styles={flexStable}>
          <SearchBox />
        </Flex>
      </Flex>
      <Flex w="100%" h="100%" p="sm" justify="space-between" gap="md" direction="column">
        <StandardLayoutTableWrapper>
          <ListTable<PurchaseOrdersGet200Response, PurchaseOrdersGet200ResponseRowsInner>
            columns={columns}
          />
        </StandardLayoutTableWrapper>
        <PaginationBox<PurchaseOrdersGet200Response> />
      </Flex>
    </Flex>
  );
};
